/**
 * @todo
 * proper google auth onboarding collecting the photoUrl of anyone you logs in via google
 * authentication save the photoUrl in their 'users' record
 *
 * sign out from 3 dot menu in top left above logo  DONE
 * make chatbout logo
 * ask for name on register
 * take 1st letter of email address or name
 * random colour for avatar circle in chat
 * count how many active chats there are and display in side bar
 * same for total users
 * tailwind speech bubble
 * enter chat nessage with return button
 *
 *
 * user is typing message: detect on/change in input field, update the chat record with typing: true
 * this will prompt useEffect onsnap to update, typing message can be displayed
 * setInterval for typing message to turn off in 5 seconds
 * user is only can follow similar coding
 *
 */

import React, { useState, useEffect } from "react";
import { auth, provider, db } from "../firebase-config.js";
import {
  doc,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { signInWithPopup, onAuthStateChanged, signOut, signInAnonymously, EmailAuthProvider, getAuth, linkWithCredential } from "firebase/auth";
import "../styles/Auth.scss";
import Cookies from "universal-cookie";
// import { createAvatar } from '@dicebear/core';
// import { lorelei } from '@dicebear/collection';

const cookies = new Cookies();

export const Auth = ({ setIsAuth, appMode, appLaunched }) => {
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [hasAccount, setHasAccount] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  const [launched, setLaunched] = useState(appLaunched || false)

  const clearInputs = () => {
    setEmail("");
    setPassword("");
  };

  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
  };

  const signInWithPassword = async () => {
    clearErrors();
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        setIsAuth(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  const registerWithPassword = async () => {
    clearErrors();
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        //const user = userCredential.user;
        //console.log("user", user);
        //setRegisterForm(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  const updateGoogleUserProfile = async (user) => {
    // console.log("google user to update", user);

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    const userData = {
      displayName: user.displayName,
      photoURL: user.photoURL,
      email: user.email,
      id: user.uid,
      // only if user has signed up to be a seller
      // roles: { ECOMMERCE: "ECOMMERCE" },
      status: "online",
      updatedAt: serverTimestamp(),
    };

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      try {
        await updateDoc(docRef, { ...userData });
      } catch (err) {
        console.log("error:", err);
      }
    } else {
      // docSnap.data() will be undefined in this case
      // console.log("No such document!");
      try {
        await setDoc(docRef, userData);
      } catch (err) {
        console.log("error:", err);
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log("result", result);
      //get the photoUrl and other data and update it in the 'users' profile for this user
      updateGoogleUserProfile(result.user);
      cookies.set("auth-token", result.user.refreshToken);
      setIsAuth(true);
    } catch (err) {
      console.error(err);
    }
  };

  const signInAnon = async () => {
    // user input nickname
    try {
      const result = await signInAnonymously(auth);
      cookies.set("auth-token", result.user.refreshToken);
      // console.log('result', result)
      // update user with displayName
      // const avatar = (id) => {
      //   return(createAvatar(lorelei, {
      //     seed: id,
      //     // ... other options
      //   }))
      // } 
      result.user.displayName = result.user.uid.substring(0,4) + " Guest";
      result.user.photoURL = "https://api.dicebear.com/9.x/identicon/png?seed="+result.user.uid.substring(0,6) //"https://thisisobah.com/img/obah-logo.png";
      updateGoogleUserProfile(result.user)
      setIsAuth(true);

    } catch (err) {
      console.error(err)
    }
  }

  // const signInWithFacebook = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, facebookProvider);
  //     console.log("result", result);
  //     debugger;
  //     cookies.set("auth-token", result.user.refreshToken);
  //     setIsAuth(true);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const signInWithApple = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, appleProvider);
  //     console.log("result", result);
  //     debugger;
  //     cookies.set("auth-token", result.user.refreshToken);
  //     setIsAuth(true);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const currentUser = (e) => {
    onAuthStateChanged(auth, (user) => {
      e.preventDefault();
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        // const uid = user.uid;
        // console.log("currentUser", user);
        // ...
      } else {
        // User is signed out
        // ...
        // console.log("user is signed out");
      }
    });
  };

  const logout = async (e) => {
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        e.preventDefault();
        setIsAuth(false);
        console.log("signed out");
        cookies.remove("auth-token");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const authListener = () => {
    // console.log('authListener')
    onAuthStateChanged(auth, (user) => {

      if (user) {
        // console.log('user', user)
        // console.log('appMode', appMode)

        clearInputs();
        setUser(user);
        signInAnon();
      } else {
        console.log('no user persent. now go do signInAnon')

        setUser("");
        signInAnon();
      }

    });
  };
  useEffect(() => {
    // console.log('launched',launched)
    if(appMode && appMode.login === 'true') {
      // console.log('lets get logged in')
      setIsAuth(false)
      // setRegisterForm(true)
      //do nothing just pause at the login form
    } else {
      // console.log('now call authListener')
      authListener();
    }
    

  }, [launched]);

  return (
    <>
      <div className="rounded-lg bg-black p-6">
        <h1 className="text-white py-2 text-center font-bold text-2xl">
          {/* {registerForm ? <span>Register</span> : <span>Login</span>} */}
        </h1>
        <div className="auth p-6 text-center">
          <img src="https://thisisobah.com/img/muuzbox-logo.png" className="loadlogo" alt="logo" />
          <button
            onClick={signInWithGoogle}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center"
          >
            <svg
              fill="#000000"
              width="30px"
              height="20px"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>ionicons-v5_logos</title>
              <path d="M473.16,221.48l-2.26-9.59H262.46v88.22H387c-12.93,61.4-72.93,93.72-121.94,93.72-35.66,0-73.25-15-98.13-39.11a140.08,140.08,0,0,1-41.8-98.88c0-37.16,16.7-74.33,41-98.78s61-38.13,97.49-38.13c41.79,0,71.74,22.19,82.94,32.31l62.69-62.36C390.86,72.72,340.34,32,261.6,32h0c-60.75,0-119,23.27-161.58,65.71C58,139.5,36.25,199.93,36.25,256S56.83,369.48,97.55,411.6C141.06,456.52,202.68,480,266.13,480c57.73,0,112.45-22.62,151.45-63.66,38.34-40.4,58.17-96.3,58.17-154.9C475.75,236.77,473.27,222.12,473.16,221.48Z" />
            </svg>{" "}
            <span> Sign In With Google </span>
          </button>
        </div>
        {/* <hr /> */}

        <div className="auth p-6 text-center">
          {/* <button
            onClick={signInAnon}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center"
          >
            
            <span> Anonymous Guest </span>
          </button> */}
        </div>


        {/* {registerForm ? (
          <div className="auth">
            <p> Register In With Email/Password To Continue </p>
            <input
              className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <input
              className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
              type="password"
              id="authPassword"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button onClick={() => registerWithPassword()}>
              Register With Password
            </button>
            <br />
            <span
              className="text-white cursor-pointer"
              onClick={() => setRegisterForm(!registerForm)}
            >
              ready to Login
            </span>
          </div>
        ) : (
          <div className="auth">
            <p> Sign In With Email/Password To Continue </p>
            <div className="py-4">
              <input
                className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                type="email"
                id="email"
                value={email}
                placeholder="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="py-4">
              <input
                className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                type="password"
                id="authPassword"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="py-4 text-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center"
                onClick={() => signInWithPassword()}
              >
                <svg
                  width="30px"
                  height="20px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M10 11H2.05C2.55 5.947 6.814 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-5.185 0-9.449-3.947-9.95-9H10v3l5-4-5-4v3z" />
                  </g>
                </svg>
                <span>Sign In With Password</span>
              </button>
            </div>
            <div>
              <span
                className="text-white cursor-pointer"
                onClick={() => setRegisterForm(!registerForm)}
              >
                Register
              </span>
            </div>
          </div>
        )} */}

        {/* <hr />
        <div className="auth">
          <p> Sign Out </p>
          <button onClick={(e) => logout(e)}> Sign Out </button>
        </div>
        <hr />
        <div className="auth">
          <p> Current User </p>
          <button onClick={(e) => currentUser(e)}> Current User </button>
        </div> */}

        {/* <div className="auth">
        <p> Sign In With Facebook To Continue </p>
        <button onClick={signInWithFacebook}> Sign In With Facebook </button>
      </div>

      <div className="auth">
        <p> Sign In With Apple To Continue </p>
        <button onClick={signInWithApple}> Sign In With Apple </button>
      </div> */}
      </div>
    </>
  );
};
