import React from 'react'

const CoverImage = (props) => {
  
  return (
    <div
      className="flex items-center justify-center h-16 w-16 m-4 rounded-full border overflow-hidden ebzbg-indigo-500 flex-shrink-0"
      style={{ backgroundImage:`url('${props.src}')`, backgroundSize: 'cover' }}
    >
      
    </div>
  )
}

export default CoverImage
