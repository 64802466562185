import React, { useState, useEffect, useRef } from "react";
import { Auth } from "./components/Auth.js";
import { AppLayout } from "./components/AppLayout";
import Cookies from "universal-cookie";
import "./AppMuuzbox.scss";
import { AuthProvider } from "./context/AuthUserContext";
// import { useIsVisible } from "./hooks/useIsVisible";

const cookies = new Cookies();

function ChatApp({items}) {
  // const ref = useRef();
  // const isLaunched = useIsVisible(ref);
  const [isAuth, setIsAuth] = useState(() => {
    if(window.location.hostname === "admin.muuzbox.com") {
      // console.log('self aware web component')
      return false;
    } else {
      return cookies.get("auth-token")
    }
    
  });
  const [isInChat, setIsInChat] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [appMode, setAppMode] = useState(() => {
    if(window.location.hostname === "admin.muuzbox.com") {
      // console.log('self aware web component')
      return {login: 'true'};
    } else {
      return null
    }
    
  })
  const [isLaunched, setIsLaunched] = useState(false)
  


  useEffect(() => {
    // console.log('appMode', appMode)
    // console.log('isAuth', isAuth)

  }, [appMode]);

  if (!isAuth) {
    return (
      <AuthProvider>
        <AppLayout
          isAuth={isAuth}
          setIsAuth={setIsAuth}
          setIsInChat={setIsInChat}
          setIsLaunched={setIsLaunched}
          appMode={appMode}
        >
          <div
            className="bg-black bg-opacity-80 app-container max-w-full"
            x="bg-black bg-opacity-80 h-screen w-screen top-0 left-0 fixed z-10"
          >
            <div className="h-screen flex items-center justify-center">
              <Auth setIsAuth={setIsAuth} appMode={appMode} appLaunched={isLaunched} />
            </div>
          </div>
        </AppLayout>
      </AuthProvider>
    );
  }

  return (
    <AuthProvider>
      <AppLayout
        setIsLaunched={setIsLaunched}
        isAuth={isAuth}
        setIsAuth={setIsAuth}
        setIsInChat={setIsInChat}
        setChatId={setChatId}
        appMode={appMode}
      ></AppLayout>
    </AuthProvider>
  );
}

export default ChatApp;
