import React, { useState, useEffect } from "react";

import "../styles/Chat.scss";

export const LoadingAnimation = () => {
  return (
    <>
      <div className="flex flex-col flex-auto p-6 jukebox-panel hiddenchat">
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 dark:bg-gray-900 h-full p-4 panel-fade">
          <div
            className={`flex flex-row items-center h-16 rounded-xl w-full px-4 mb-4 text-enter-fit`}
          >
            <div>
              <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="flex flex-col h-full overflow-x-auto mb-4">
            <div className="flex flex-col h-full">
              <div className="grid grid-cols-12 gap-y-2">
                <div class="muuzbox-loader-logo-sm2 fadeIn">
                  <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
                  <div class="muuzbox-loader-blob"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
