import React from 'react'
// import styles from './Volume.css'

const Volume = (props) => {
  return (
    <div className="flex flex-row justify-start items-center pl-2">
      <svg style={{"color": "white", "width": "20px", "height": "20px"}} className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M155.5,24.8a8,8,0,0,0-8.4.9L77.2,80H32A16,16,0,0,0,16,96v64a16,16,0,0,0,16,16H77.2l69.9,54.3A7.9,7.9,0,0,0,152,232a8.5,8.5,0,0,0,3.5-.8A8,8,0,0,0,160,224V32A8,8,0,0,0,155.5,24.8Z" fill="white"></path></svg>
      <input
        type='range'
        min='1'
        max='100'
        defaultValue='80'
        // className={styles.slider}
        id='myRange'
        onChange={props.onChange}
        style={{
          background: `linear-gradient(90deg, var(--volumeUsed) ${
            props.value * 100
          }%, var(--volumeLeft) ${props.value * 100}%)`
        }}
      />
    </div>
  )
}

export default Volume
