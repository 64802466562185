// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, OAuthProvider, TwitterAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";


// const firebaseConfig = {
//   apiKey: "AIzaSyBw8kPQkxNvVY2cB6ghVc20FlxhP4e_Xs4",
//   authDomain: "testproj-531d7.firebaseapp.com",
//   projectId: "testproj-531d7",
//   storageBucket: "testproj-531d7.appspot.com",
//   messagingSenderId: "705585244276",
//   appId: "1:705585244276:web:6905459d1c0800f7ba0921",
//   measurementId: "G-KWJSWQRNV0"
// };

const firebaseConfig = {
  apiKey: 'AIzaSyDRNYhuPiy1VnG8tvXdSiUwV6eqfzMfCFM',
  authDomain: 'chatbout001.firebaseapp.com',
  databaseURL: 'https://chatbout001.firebaseio.com',
  projectId: 'chatbout001',
  storageBucket: 'chatbout001.appspot.com',
  messagingSenderId: '410612721827',
  appId: '1:410612721827:web:540d6883be019799763716',
};

const firebaseConfig2 = {
  apiKey: "AIzaSyAFV_tj0OribIwpPi7M-BE3BcmY9OZeJ_Q",
  authDomain: "ctfo-music.firebaseapp.com",
  databaseURL: "https://ctfo-music-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ctfo-music",
  storageBucket: "ctfo-music.appspot.com",
  messagingSenderId: "331144454129",
  appId: "1:331144454129:web:a545083b4756c5b384ae50",
  measurementId: "G-EZQZ42G0WW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const app2 = initializeApp(firebaseConfig2, "other");
export const db = getFirestore(app);
export const db2 = getFirestore(app2);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const twitterProvider = new TwitterAuthProvider();
export const appleProvider = new OAuthProvider('apple.com');
export const microsdoftProvider = new OAuthProvider('microsoft.com');
export const facebookProvider = new FacebookAuthProvider();
//export const passwordProvider =  createUserWithEmailAndPassword();