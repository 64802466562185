import React, { useState, useEffect, useRef } from "react";
import { auth, db, db2 } from "./firebase-config";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  addDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { useDropzone } from "react-dropzone";
import "./JukeboxRequests.scss";
import msgs from "./data.json";
import { Avatar } from "./Avatar";
import MuuzTubeSearch from "../MuuzTubeSearch";
import CurrentPlaylist from "./CurrentPlaylist";
import { LoadingAnimation } from "../LoadingAnimation";

function App({ filedata }) {
  const chatbase = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [jukebox, setJukebox] = useState([]);
  const [requstList, setRequstList] = useState([]);
  const [requestShow, setRequestShow] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [acceptedSong, setAcceptedSong] = useState({
    id: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
  });
  const [chatmessage, setChatmessage] = useState("");
  const [avatars, setAvatars] = useState({});
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState("5fD03BaD7INyhJMne2gfLdPAKbQ2");
  const [cannedResponseShow, setCannedResponseShow] = useState(true);
  const [cannedResponse, setCannedResponse] = useState(msgs);
  const [requesterListShow, setRequesterListShow] = useState(false);
  const [platform, setPlatform] = useState("");
  const [showYoutubeSearch, setShowYoutubeSearch] = useState(false);
  const [createPlaylistName, setCreatePlaylistName] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [showPlaylists, setShowPlaylists] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [editPlaylistModal, setEditPlaylistModal] = useState(false);
  const [currentPlaylist, setCurrentPlaylist] = useState([]);
  const [playlistId, setPlaylistId] = useState(null);
  const [showLoadingAni, setShowLoadingAni] = useState(false);

  useEffect(() => {
    // console.log("OS:", window.navigator.userAgentData.platform);
    setPlatform(window.navigator.userAgentData.platform);

    const requestFeedbackRef = collection(db, "djchats");
    const queryMessages = query(requestFeedbackRef, orderBy("createdAt")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        if (!(doc.data().status && doc.data().status === "closed")) {
          requestFeedback.push({ ...doc.data(), id: doc.id });
        }
      });
      setRequstList(requestFeedback);
    });
    return () => unsuscribe();
  }, []);

  useEffect(() => {
    const playlistsRef = collection(
      db,
      "users",
      auth.currentUser.uid,
      "playlists"
    );
    const queryPlaylists = query(playlistsRef, orderBy("createdAt"));
    const unsubscribe = onSnapshot(queryPlaylists, (snapshot) => {
      const playlists = [];
      snapshot.forEach((doc) => {
        playlists.push(doc.data());
      });
      setPlaylists(playlists);
    });
    return () => unsubscribe();
  }, [showPlaylists]);

  useEffect(() => {
    const chatsMessgesRef = collection(db, "djchats", chatId, "messages");
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName || null,
              photoURL: docSnap.data().photoURL || null,
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
        });
    });
    return () => unsubscribe();
  }, [chatId]);

  useEffect(() => {
    const getData = async () => {
      if (filedata) {
        const obj = JSON.parse(filedata);
        setJukebox(obj.jukebox);
      }
    };
    getData();
  }, [filedata]);

  const vtp = (video) => {
    console.log("JukeboxRequests:");
    console.log("video", video);
  };

  const handleShowPlaylist = async (e) => {
    e.preventDefault();
    closeAllTabs();
    setShowPlaylists(true);
  };

  const handJukeboxGoLive = async (e) => {
    e.preventDefault();
    // send this whole list to firebase so it can be picked up by the  chabout Web Component App
    setRequestShow(false);

    // const docRefx = doc(db2, "jukebox2", "CURRENTJUKEBOX");
    // await setDoc(docRefx, {
    //   updatedAt: serverTimestamp(),
    //   jukeboxList: jukebox,
    // });

    const docRef = doc(db2, "jukebox", "CURRENTJUKEBOX");
    await setDoc(docRef, {
      updatedAt: serverTimestamp(),
      jukeboxList: jukebox,
    });
    alert("New Jukebox Saved");

    // const chatsMessgesRef = collection(db2, "messages");
    // await addDoc(chatsMessgesRef, {orignal:"eebzookee"});

    // const docSnap = await getDoc(docRef);
    // const currentChat = { currentChat: chatId };

    // if (docSnap.exists()) {
    //   try {
    //     await updateDoc(docRef, { ...currentChat });
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
  };

  const handlePlaylistCreation = (e) => {
    closeAllTabs();
    setCreatePlaylistName(true);
  };

  const handleCheckRequests = async (e) => {
    e.preventDefault();
    closeAllTabs();
    setRequestShow(true);
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
    // getFilesFromEvent: (event) => myCustomFileGetter(event),
  });
  const files = acceptedFiles.map((f) => <li key={f.name}>{f.path}</li>);

  const acceptRequest = (e, song) => {
    e.preventDefault();
    setMessages("");
    setChatId(song.id);
    setAcceptedSong(song);
    // before shoiwng the modal get the requester list ready
    const requesters = requstList.filter((req) => req.id === song.id);
    setResponseModal(!responseModal);
  };

  const handleChat = async (event) => {
    let value = event.target.value;
    setChatmessage(value);
  };

  const handlePlaylist = async (event) => {
    let value = event.target.value;
    setPlaylistName(value);
  };

  const updateDjChatLength = async (chatId) => {
    const docRef = doc(db, "djchats", chatId);
    const unreadAmount = { unreadAmount: messages.length + 1 };
    await updateDoc(docRef, {
      ...unreadAmount,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    const docRef = collection(db, "djchats", chatId, "messages");
    await addDoc(docRef, {
      text: chatmessage,
      createdAt: serverTimestamp(),
      user: "DJ OBAH",
      uid: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
    });
    updateDjChatLength(chatId);
    setChatmessage("");
    setMessages("");

    setTimeout(() => {
      chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const handleSubmitPlaylist = async (event) => {
    event.preventDefault();
    setPlaylistName("");
    closeAllTabs();
    // create new playlist in firehost
    // record the playist id in the users playlist profile array

    // playlists will still be able to be broken down in the genre lists like the nlm jukebox lists

    const docRef = collection(db, "playlists");
    const newpl = await addDoc(docRef, {
      playlistName: playlistName,
      createdAt: serverTimestamp(),
      user: auth.currentUser.displayName,
      uid: auth.currentUser.uid,
    });

    await setDoc(
      doc(db, "users", auth.currentUser.uid, "playlists", newpl.id),
      {
        id: newpl.id,
        playlistName: playlistName,
        createdAt: serverTimestamp(),
      }
    );

    // clear the input field and add the new playlist name to the drop down
  };

  const addCannedResponse = async (e, canned) => {
    e.preventDefault();
    const docRef = collection(db, "djchats", chatId, "messages");
    await addDoc(docRef, {
      text: canned.text,
      createdAt: serverTimestamp(),
      user: "DJ OBAH",
      uid: "5fD03BaD7INyhJMne2gfLdPAKbQ2",
    });
    updateDjChatLength(chatId);
    setChatmessage("");
    setMessages("");
    //setCannedResponseShow(false);

    setTimeout(() => {
      chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const searchYoutube = async (e, song) => {
    e.preventDefault();
    setShowYoutubeSearch(!showYoutubeSearch);
    // target the container and switch to youtube search form

    // const docRef = doc(db, "djchats", song.id);
    // const status = { status: "closed" };
    // await updateDoc(docRef, {
    //   ...status,
    // });
  };

  const rejectRequest = async (e, song) => {
    e.preventDefault();
    const docRef = doc(db, "djchats", song.id);
    const status = { status: "closed" };
    await updateDoc(docRef, {
      ...status,
    });
  };

  const closeAllTabs = async () => {
    setRequestShow(false);
    setCreatePlaylistName(false);
    setShowPlaylists(false);
  };

  const updateCurrentPlaylist = (playlistId) => {
    setEditPlaylistModal(!editPlaylistModal);
    setShowLoadingAni(true);
    setTimeout(() => {
      editPlaylist(null, playlistId);
    }, 2000);
  };

  const editPlaylist = (e, playlistId) => {
    if (e) e.preventDefault();
    setEditPlaylistModal(true);
    let playlist = [];
    const playlistRef = collection(db, "playlists", playlistId.id, "tracks");
    const queryList = query(playlistRef, orderBy("ordinal")); //, where("createdAt", "<", serverTimestamp()), orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryList, (snapshot) => {
      playlist = [];
      snapshot.forEach(async (docItem) => {
        //messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
        const trackDocRef = doc(db, "tracks", docItem.data().id);
        const docSnap = await getDoc(trackDocRef);
        if (docSnap.exists()) {
          playlist.push({
            id: docSnap.id,
            track: docSnap.data().track,
            genre: docSnap.data().genre,
            artist: docSnap.data().artist,
            coverart: docSnap.data().coverart,
            url: docSnap.data().url,
            youTubeId: docSnap.data().youTubeId,
          });
        }
      });
      setTimeout(() => {
        console.log("playlist", playlist);
        setShowLoadingAni(false);
        setCurrentPlaylist(playlist);
      }, 1500);
    });
    setPlaylistId(playlistId);

    return () => unsuscribe();

    // show selected playlist which is a list of track ids from the tracks collection
    // show them as accordian list that opens for more info
    // each track has the usual title, artist,  coverart, genre, url, youtubeURL
    // show edit button to show form to modify any of these fields
    // show input that will load up youtube search as well as the youtube button that will search acording to title  and artist
    //
  };

  let lastID = 0;
  let displayName = "DJ OBAH";
  let email = "thisisobah@gmail.com";

  return (
    <div className="JukeboxRequests">
      <section className="request-container">
        {platform === "macOS" && <div id="eebz"></div>}
      </section>
      <section className="request-container">
        {/* <div id="dragged">
          DRAG FILE HERE
        </div> */}

        <div id="dragged" className="" {...getRootProps()}>
          {platform !== "macOS" ? (
            <>
              <div className="bg-white shadow-md rounded-md overflow-hidden mx-auto ">
                <div id="eebz" className="">
                  <form>
                    <input
                      className="uplaodHiddenBtn"
                      type="file"
                      id="myFile"
                      name="filename"
                      {...getInputProps()}
                    />
                    <button
                      type="submit"
                      class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2 mr-2"
                    >
                      Upload Playlist
                    </button>
                  </form>
                </div>
              </div>

              {/* <div className="btn">
                <input {...getInputProps()} />
                <span role="img" aria-label="books">
                  ▶️ &nbsp;
                </span>

                Load Playlist for patrons
                <ul>{files}</ul>
              </div> */}
            </>
          ) : (
            <>
              {/* <div className="bg-white shadow-md rounded-md overflow-hidden max-w-lg mx-auto mt-16">
                <div id="eebz" className="btn-container">
                  <form>
                    <input
                      className="uplaodHiddenBtn"
                      type="file"
                      id="myFile"
                      name="filename"
                      {...getInputProps()}
                    />
                    <button
                      type="submit"
                      class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-2 py-2 text-center me-2 mb-2 mr-2"
                    >
                      Upload Playlist
                    </button>
                  </form>
                </div>
              </div> */}

              <div className="btn">
                <input {...getInputProps()} />
                <span role="img" aria-label="books">
                  ▶️ &nbsp;
                </span>
                Upload Playlist
                <ul>{files}</ul>
              </div>
            </>
          )}

          {/* <ul>
            {uploadedFiles.map((file: any) => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul> */}
          <section className="request-container">
            <div className="bg-white shadow-md rounded-md overflow-hidden">
              {/* <div className="bg-gray-100 py-1 px-1"> */}
              <h2 className="text-xl font-semibold text-gray-800">
                MUUZBOX CRATE
              </h2>
              <button
                onClick={(e) => handleShowPlaylist(e)}
                // onClick={(e) => handJukeboxGoLive(e)}
                type="button"
                class="text-black bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
              >
                Show Playlists
              </button>
              <button
                onClick={(e) => handlePlaylistCreation(e)}
                // onClick={(e) => handJukeboxGoLive(e)}
                type="button"
                class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
              >
                Create Playlist +
              </button>
              <button
                onClick={(e) => handleCheckRequests(e)}
                type="button"
                class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2"
              >
                Check Requests
              </button>
              {/* </div> */}
              <ul className="divide-y divide-gray-200">
                {requestShow &&
                  requstList &&
                  requstList.map((item, i) => (
                    <li
                      onClick={(e) => acceptRequest(e, item)}
                      className="flex items-center py-4 px-6 cursor-pointer"
                      key={i + 1}
                    >
                      <span className="text-gray-700 text-lg font-medium mr-4">
                        {i}.
                      </span>
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4"
                        src={item.request.coverart}
                        style={{ width: "24px" }}
                        alt={item.request.track}
                      />
                      <div className="flex-1">
                        <h3 className="text-lg font-medium text-gray-800">
                          {item.request.artist}
                        </h3>
                        <p className="text-gray-600 text-base">
                          {item.request.track}{" "}
                        </p>
                        <p className="text-gray-600 text-base border-t-2 border-gray-600">
                          <i>requested by</i> : {item.user}
                        </p>
                      </div>
                    </li>
                  ))}
                {!requestShow &&
                  jukebox &&
                  jukebox.map((item, i) => (
                    <li className="flex items-center py-4 px-6" key={i + 1}>
                      <span className="text-gray-700 text-lg font-medium mr-4">
                        {i}.
                      </span>
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4"
                        src={item.coverart}
                        style={{ width: "24px" }}
                        alt={item.track}
                      />
                      <div className="flex-1">
                        <h3 className="text-lg font-medium text-gray-800">
                          {item.artist}
                        </h3>
                        <p className="text-gray-600 text-base">{item.track} </p>
                        <p className="text-gray-600 text-base">{item.genre} </p>
                      </div>
                    </li>
                  ))}
              </ul>
              {createPlaylistName && (
                <div className="flex flex-row flex-grow w-full">
                  <div className="text-black flex-grow">
                    <input
                      id="new-playlist"
                      type="text"
                      placeholder="new playlist"
                      value={playlistName}
                      onChange={handlePlaylist}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleSubmitPlaylist(e);
                      }}
                      className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8"
                    />
                  </div>
                  <div className="ml-2">
                    <button
                      onClick={(event) => handleSubmitPlaylist(event)}
                      className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
                    >
                      <span className="ml-0">
                        <svg
                          className="w-8 h-8 -mt-px text-white"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                            stroke="#ffffff"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {showPlaylists && (
                <div className="flex flex-row flex-grow w-full">
                  <div className="text-black flex-grow">
                    playlists
                    <ul>
                      {showPlaylists &&
                        playlists.map((item, i) => (
                          <li
                            className="flex flex-row items-center  text-left hover:bg-gray-100 rounded-xl p-2"
                            key={i}
                          >
                            <div className="flex-grow">{item.playlistName}</div>
                            <button className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end">
                              launch
                            </button>
                            <button
                              onClick={(e) => editPlaylist(e, item)}
                              className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-lg px-2 py-2 text-center me-2 mb-2 mr-2 justify-self-end"
                            >
                              edit
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            {showLoadingAni && (
              <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel mt-24">
                <LoadingAnimation />
              </div>
            )}

            {editPlaylistModal && (
              <>
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel">
                  <div className="flex justify-between text-white">
                    {/* <button
                      onClick={() => {
                        setRequesterListShow(!requesterListShow);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-green-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {requesterListShow ? (
                        <>
                          <div>MESSAGES</div>
                        </>
                      ) : (
                        <>
                          <div>REQUESTED BY</div>
                        </>
                      )}
                    </button> */}
                    <button
                      onClick={(e) => {
                        searchYoutube(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          fill="#ffffff"
                          className="mr-1"
                          width="14px"
                          height="14px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.027 9.92L16 13.95 14 16l-4.075-3.976A6.465 6.465 0 0 1 6.5 13C2.91 13 0 10.083 0 6.5 0 2.91 2.917 0 6.5 0 10.09 0 13 2.917 13 6.5a6.463 6.463 0 0 1-.973 3.42zM1.997 6.452c0 2.48 2.014 4.5 4.5 4.5 2.48 0 4.5-2.015 4.5-4.5 0-2.48-2.015-4.5-4.5-4.5-2.48 0-4.5 2.014-4.5 4.5z"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <div>YOUTUBE</div>
                      </div>
                    </button>
                    <button
                      onClick={(e) => {
                        rejectRequest(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>UPLOAD NLM</div>
                    </button>
                    <button
                      onClick={(e) => {
                        rejectRequest(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>UPLOAD M3U</div>
                    </button>

                    <button
                      onClick={() => {
                        setEditPlaylistModal(!editPlaylistModal);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>X</div>
                    </button>
                  </div>
                  <CurrentPlaylist
                    currentPlaylist={currentPlaylist}
                    playlistId={playlistId}
                    updateCurrentPlaylist={updateCurrentPlaylist}
                  />
                </div>
              </>
            )}

            {responseModal && (
              <>
                <div className="bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 dark:bg-gray-700 muuz-confirm-panel">
                  <div className="flex justify-between text-white">
                    <button
                      onClick={() => {
                        setRequesterListShow(!requesterListShow);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-green-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      {requesterListShow ? (
                        <>
                          <div>MESSAGES</div>
                        </>
                      ) : (
                        <>
                          <div>REQUESTED BY</div>
                        </>
                      )}
                    </button>
                    <button
                      onClick={(e) => {
                        searchYoutube(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          fill="#ffffff"
                          className="mr-1"
                          width="14px"
                          height="14px"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.027 9.92L16 13.95 14 16l-4.075-3.976A6.465 6.465 0 0 1 6.5 13C2.91 13 0 10.083 0 6.5 0 2.91 2.917 0 6.5 0 10.09 0 13 2.917 13 6.5a6.463 6.463 0 0 1-.973 3.42zM1.997 6.452c0 2.48 2.014 4.5 4.5 4.5 2.48 0 4.5-2.015 4.5-4.5 0-2.48-2.015-4.5-4.5-4.5-2.48 0-4.5 2.014-4.5 4.5z"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <div>YOUTUBE</div>
                      </div>
                    </button>
                    <button
                      onClick={(e) => {
                        rejectRequest(e, acceptedSong);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>REJECT</div>
                    </button>

                    <button
                      onClick={() => {
                        setResponseModal(!responseModal);
                      }}
                      className="block text-center flex justify-end px-4 py-2 text-black hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <div>X</div>
                    </button>
                  </div>

                  <div className="text-center">
                    <div className="block w-full text-xl text-center text-red-800 h-8 text-ellipsis overflow-hidden">
                      {acceptedSong.request.track}
                    </div>
                    <div className="block w-full text-xl text-center text-black h-8 text-ellipsis overflow-hidden">
                      {acceptedSong.request.artist}
                    </div>
                    {showYoutubeSearch && (
                      <div className=" rounded-xl bg-slate-400 px-4 h-96 overflow-auto">
                        <MuuzTubeSearch acceptedSong={acceptedSong} vtp={vtp} />
                      </div>
                    )}
                  </div>

                  {!requesterListShow && !showYoutubeSearch && (
                    <>
                      <div
                        className="py-2 text-sm text-gray-700 dark:text-gray-200 chat-container"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <div
                          className="text-center overflow-y-auto overflow-x-auto"
                          ref={chatbase}
                        >
                          {/* <div className="block w-full text-xl text-center text-red-800">
                        {acceptedSong.request.track}
                      </div>
                      <div className="block w-full text-xl text-center">
                        {acceptedSong.request.artist}
                      </div> */}

                          {cannedResponseShow &&
                            cannedResponse.map((item, i) => (
                              <div
                                className="flex flex-row items-center justify-between text-left hover:bg-gray-100 rounded-xl p-2"
                                key={i}
                                onClick={(e) => addCannedResponse(e, item)}
                              >
                                <div className="ml-2">{item.text}</div>
                                <div className="flex items-center justify-center h-8 w-8 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                                  <button className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-4 h-4">
                                    <span className="ml-0">
                                      <svg
                                        className="w-4 h-4 transform rotate-45 -mt-px"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                        ></path>
                                      </svg>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            ))}

                          {messages.length > 0 &&
                            messages.map((message, index) => {
                              let noshow = true;
                              if (message.uid !== lastID) {
                                noshow = false;
                                lastID = message.uid;
                              }
                              return (
                                <div
                                  className={
                                    displayName === message.user ||
                                    email === message.user
                                      ? "col-start-2 col-end-13 p-3 rounded-lg chatbubble"
                                      : "col-start-1 col-end-12 p-3 rounded-lg chatbubble"
                                  }
                                  key={index}
                                >
                                  <div
                                    className={
                                      displayName === message.user ||
                                      email === message.user
                                        ? "flex items-center justify-start flex-row-reverse"
                                        : "flex flex-row items-center"
                                    }
                                  >
                                    {!noshow && (
                                      <div
                                        className={
                                          displayName === message.user ||
                                          email === message.user
                                            ? "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0  z-10"
                                            : "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0  z-10"
                                        }
                                      >
                                        <Avatar
                                          photoURL={message.photoURL}
                                          message={message}
                                        />
                                      </div>
                                    )}
                                    <div
                                      className={
                                        displayName === message.user ||
                                        email === message.user
                                          ? "relative mr-3 text-sm text-gray-800 dark:text-gray-800 bg-indigo-100 py-2 px-4 shadow rounded-l-xl rounded-br-xl z-10"
                                          : "relative ml-3 text-sm text-gray-800 dark:text-gray-800 bg-white py-2 px-4 shadow rounded-e-xl rounded-es-xl  z-10"
                                      }
                                    >
                                      <div>
                                        {/* {index} : {message.user} : */}{" "}
                                        {message.text}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>

                        {/* <div class="muuzbox-loader-logo-sm fadeIn">
                        <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
                        <div class="muuzbox-loader-blob"></div>
                      </div> */}
                      </div>

                      <div
                        className={`flex flex-row items-center rounded-xl bg-white w-full px-4 py-4 h-8 text-enter-fit text-enter-full chatbout-input `}
                      >
                        <div className="flex-grow ml-2 h-12">
                          <div className="relative w-full">
                            <input
                              id="response-chat"
                              type="text"
                              placeholder="response chat"
                              value={chatmessage}
                              // onFocus={() => setInputFocus(true)}
                              onChange={handleChat}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleSubmit(e);
                              }}
                              className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-8"
                            />
                          </div>
                        </div>
                        <div className="ml-2">
                          <button
                            onClick={(event) => handleSubmit(event)}
                            className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
                          >
                            <span className="ml-0">
                              <svg
                                className="w-8 h-8 transform rotate-45 -mt-px"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                ></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {requesterListShow && (
                    <>
                      <div>requester list</div>
                    </>
                  )}
                </div>
              </>
            )}
          </section>
        </div>
      </section>
    </div>
  );
}

export default App;

// <div className="bg-white shadow-md rounded-md overflow-hidden max-w-lg mx-auto mt-16">
//     <div className="bg-gray-100 py-2 px-4">
//         <h2 className="text-xl font-semibold text-gray-800">Top Users</h2>
//     </div>
//     <ul className="divide-y divide-gray-200">
//         <li className="flex items-center py-4 px-6">
//             <span className="text-gray-700 text-lg font-medium mr-4">1.</span>
//             <img className="w-12 h-12 rounded-full object-cover mr-4" src="https://randomuser.me/api/portraits/women/72.jpg"
//                 alt="User avatar">
//             <div className="flex-1">
//                 <h3 className="text-lg font-medium text-gray-800">Emily Jones</h3>
//                 <p className="text-gray-600 text-base">1234 points</p>
//             </div>
//         </li>
//         <li className="flex items-center py-4 px-6">
//             <span className="text-gray-700 text-lg font-medium mr-4">2.</span>
//             <img className="w-12 h-12 rounded-full object-cover mr-4" src="https://randomuser.me/api/portraits/men/40.jpg"
//                 alt="User avatar">
//             <div className="flex-1">
//                 <h3 className="text-lg font-medium text-gray-800">David Lee</h3>
//                 <p className="text-gray-600 text-base">987 points</p>
//             </div>
//         </li>
//         <li className="flex items-center py-4 px-6">
//             <span className="text-gray-700 text-lg font-medium mr-4">3.</span>
//             <img className="w-12 h-12 rounded-full object-cover mr-4" src="https://randomuser.me/api/portraits/women/54.jpg"
//                 alt="User avatar">
//             <div className="flex-1">
//                 <h3 className="text-lg font-medium text-gray-800">Sophia Williams</h3>
//                 <p className="text-gray-600 text-base">876 points</p>
//             </div>
//         </li>
//         <li className="flex items-center py-4 px-6">
//             <span className="text-gray-700 text-lg font-medium mr-4">4.</span>
//             <img className="w-12 h-12 rounded-full object-cover mr-4" src="https://randomuser.me/api/portraits/men/83.jpg"
//                 alt="User avatar">
//             <div className="flex-1">
//                 <h3 className="text-lg font-medium text-gray-800">Michael Chen</h3>
//                 <p className="text-gray-600 text-base">765 points</p>
//             </div>
//         </li>
//         <li className="flex items-center py-4 px-6">
//             <span className="text-gray-700 text-lg font-medium mr-4">5.</span>
//             <img className="w-12 h-12 rounded-full object-cover mr-4" src="https://randomuser.me/api/portraits/women/17.jpg"
//                 alt="User avatar">
//             <div className="flex-1">
//                 <h3 className="text-lg font-medium text-gray-800">Mia Davis</h3>
//                 <p className="text-gray-600 text-base">654 points</p>
//             </div>
//         </li>
//     </ul>
// </div>
