import React, { useState, useEffect } from "react";
import Time from "./AudioMusicPlayer/audio-player/Time";
import Progress from "./AudioMusicPlayer/audio-player/Progress";

import YouTube from "react-youtube";

const VideoDetail = ({ video, videoToPlaylist }) => {
  const [vidObj, setVidObj] = useState({ init: 0 });
  const [term, setTerm] = useState("");
  const [length, setLength] = useState(0);
  const [time, setTime] = useState(0);
  const [slider, setSlider] = useState(1);
  const [drag, setDrag] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [newTrack, setNewTrack] = useState(video);
  const [TI, setTI] = useState(null);
  let [end, setEnd] = useState(0);

  const fmtMSS = (s) => new Date(1000 * s).toISOString().substr(15, 4);

  useEffect(() => {
    // setTimeout(() => {
    if (vidObj.init !== 0) {
      // console.log("main useEffect triggered ", vidObj);
      const audio = vidObj; //new Audio(trackList[curTrack].url);
      //   const setAudioData = () => {
      setLength(audio.getDuration());
      // setTime(audio.getCurrentTime());
      //   };

      //   const setAudioTime = () => {
      const curTime = audio.getCurrentTime();
      setTime(curTime);
      setSlider(
        curTime ? ((curTime * 100) / audio.getDuration()).toFixed(1) : 0
      );
      //   };

      const setAudioVolume = () => setVolume(audio.getVolume());
      const setAudioEnd = () => setEnd((end += 1));

      // events on audio object
      //   audio.addEventListener("loadeddata", setAudioData);
      //   audio.addEventListener("timeupdate", setAudioTime);
      //   audio.addEventListener("volumechange", setAudioVolume);
      //   audio.addEventListener("ended", setAudioEnd);

      //   setAudio(audio);
      //   setCoverImage(trackList[curTrack].coverImage);
      //   setTitle(trackList[curTrack].title);
      //   setArtist(trackList[curTrack].artist);
      //   setReleasePath(trackList[curTrack].releasePath);
      //   const pl = []
      //   const jb = jukebox.map((item, index) => {
      //     console.log('index', index)
      //     pl.push(index)
      //     return {
      //       url:
      //         item.url ||
      //         "https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FEbu%20Blackitude%20-%20Something%20Black.mp3?alt=media&token=5926ab41-e9ee-49f6-9a4b-048415d224b5",
      //       title: item.track,
      //       artist: item.artist,
      //       releasePath: "XXXX",
      //       tags: item.genre,
      //       coverImage: item.coverart,
      //       userplaylist: false,
      //     };
      //   });

      //   setPlaylist(pl);
      //   console.log('***** playlist', pl)
      //   trackList.push(...jb);

      return () => {
        audio.pauseVideo();
      };
    }
    // }, "1000");
  }, []);

  useEffect(() => {
    console.log("ended");
    // if (isInitialMount.current) {
    //   isInitialMount.current = false;
    // } else {
    //   if (shuffled) {
    //     playlist = shufflePlaylist(playlist);
    //   }
    //   !looped && autoPlayNextTrack ? next() : play();
    // }
  }, [end]);

  useEffect(() => {
    // console.log("slider", slider);
  }, [slider]);

  const play = () => {
    vidObj.playVideo();
  };

  const pause = () => {
    // console.log("vidObj", vidObj);
    vidObj.pauseVideo();
  };

  useEffect(() => {
    // console.log("video", video, vidObj);
    if (video && vidObj.init !== 0) {
      pause();
      const item = video.snippet.title;
      const coverart = video.snippet.thumbnails.high.url;
      video.snippet.artist = item.split(" - ")[0];
      video.snippet.track = item.split(" - ")[1];
      video.snippet.coverart = coverart;
    }
  }, [video, vidObj]);

  if (!video) {
    return <div>Loading Videos...</div>;
  }

  const opts = {
    height: "50",
    width: "50",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  // let timeInterval = null;

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    // console.log("event.target", event.target);

    // clearInterval(timeInterval);
    // timeInterval = setInterval(function () {
    // setCurntTime( txtBox, event.target.getCurrentTime() )
    // console.log(
    //   "event.target.getCurrentTime()",
    //   event.target.getCurrentTime()
    // );
    //   setVidObj(event.target);
    //   console.log("setVidObj...");
    // setLength(event.target.getDuration());
    // setTime(audio.getCurrentTime());
    //   };

    //   const setAudioTime = () => {
    // const curTime = event.target.getCurrentTime();
    // setTime(curTime);
    // setSlider(
    // curTime ? ((curTime * 100) / event.target.getDuration()).toFixed(1) : 0
    // );

    //   audio.addEventListener("loadeddata", setAudioData);
    // event.target.addEventListener("timeupdate", setAudioTime);
    //   audio.addEventListener("volumechange", setAudioVolume);
    //   audio.addEventListener("ended", setAudioEnd);

    //   };
    // }, 500);
    // setTI(timeInterval);
    event.target.pauseVideo();
  };

  const onStateChange = (event) => {
    // console.log("onStateChange", event.target);
    // console.log(
    //   "onStateChange event.target.getCurrentTime()",
    //   event.target.getCurrentTime()
    // );
    setVidObj(event.target);
  };

  const seek = (slider) => {
    // console.log("slider", slider);
    // slider = ((slider * 100) / vidObj.getDuration()).toFixed(1)
    setSlider(slider);
    vidObj.seekTo(slider, 1);
  };

  const handleSubmitToPlaylist = () => {
    // console.log("video", video);
    videoToPlaylist(video);
  };

  // const videoSrc = `https://www.youtube.com/embed/${video.id.videoId}`;

  return (
    <div>
      <div className="video-player">
        {/* <iframe title='video player' src={videoSrc} /> */}
        <YouTube
          className=""
          videoId={video.id.videoId}
          opts={opts}
          onReady={onReady}
          onStateChange={onStateChange}
        />
      </div>
      {/* <div className="vidoe-controls">
        <button
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={play}
        >
          play
        </button>
        <button
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          onClick={pause}
        >
          pause
        </button>

        <div className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white">{`${
          !time ? "0:00" : fmtMSS(time)
        }`}</div>
        <Progress
          className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white"
          value={slider}
          onChange={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
          }}
          onMouseUp={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
            seek(e.target.value);
          }}
          onTouchEnd={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
            seek(e.target.value);
          }}
        />
        <div className="block text-center flex justify-end px-4 py-2 text-white bg-red-600 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white">{`${
          !length ? "0:00" : fmtMSS(length)
        }`}</div>
        {/* <Volume
          value={volume}
          onChange={(e) => {
            setVolume(e.target.value / 100);
          }}
        /> */}
      {/*</div> */}
      <div className="video-description text-black text-left">
        <button
          onClick={(event) => handleSubmitToPlaylist(event)}
          className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1  w-12 h-12 mt-4 mr-2"
        >
          <span className="ml-0">
            <svg
              className="w-8 h-8 -mt-px text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6C4 4.89543 4.89543 4 6 4H12H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8 4H13V7C13 7.55228 12.5523 8 12 8H9C8.44772 8 8 7.55228 8 7V4Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 15C7 13.8954 7.89543 13 9 13H15C16.1046 13 17 13.8954 17 15V20H7V15Z"
                stroke="#ffffff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </button>
        {/* <h2 className="header">{video.snippet.title}</h2>
        <p>{video.snippet.description}</p> */}
        <div className="">artist: {video.snippet.artist}</div>
        <div className="">track: {video.snippet.track}</div>
        <div>
          cover art: <img src={video.snippet.coverart} alt="cover art" />
        </div>
      </div>
    </div>
  );
};

export default VideoDetail;
