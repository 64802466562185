import React, { useEffect, useRef, useState } from 'react';

const YouTubePlayer = ({ videoId, onPlayerReady }) => {
  const playerRef = useRef(null);

  const [fullyloaded, setFullyloaded] = useState(false);

  useEffect(() => {
    console.log('youtube play deh ting nuh man...');
    if(!fullyloaded) {
    // Dynamically load the YouTube IFrame API
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    tag.async = true;
    document.body.appendChild(tag);
      setFullyloaded(true);
    }


    // This function is called by the YouTube IFrame API
    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('youtube-player', {
        videoId: videoId, // Pass the video ID as a prop
        events: {
          onReady: onPlayerReady || (() => console.log('Player is ready')),
          onStateChange: (event) => console.log('State changed', event),
        },
      });
    };

    // Cleanup to prevent memory leaks
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
      // delete window.onYouTubeIframeAPIReady;
    };
  }, [videoId, onPlayerReady]);

  return (
    <div>
      {/* The player will be embedded in this div */}
      <div id="youtube-player" style={{ width: '100%', height: '200px' }}></div>
    </div>
  );
};

export default YouTubePlayer;
