import React from "react";
import cn from "classnames";

const AccordionItem = ({
  genreType,
  answer,
  isOpen,
  onClick,
  setRequestedTrack,
  pickTrack,
  mode,
}) => {
  // console.log("isOpen", isOpen);
  const contentHeight = React.useRef();
  return (
    <div 
      className={cn("wrapper", {
        'dark': mode === 'light',
      })}
    >
      <button
        className={`genreType-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <h3 
          className={cn("genreType-content text-lg font-medium uppercase", {
            'text-black': mode === 'light',
            'text-white': mode === 'dark',
          })}
          >
            {genreType}
        </h3>
        <svg
          className={cn("arrow", {
            'text-black': mode === 'light',
            'text-white': mode === 'dark',
            'active': isOpen,
          })}
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http:www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke={`${mode === "dark" ? "#ffffff" : "#000000"}`}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div
        ref={contentHeight}
        className={`answer-container ${isOpen ? "active" : ""}`}
      >
        <ul className="divide-y divide-gray-200">
          {answer.map((item, i) => (
            <li
              className="flex items-center py-4 px-3 jukebox-divider"
              key={i}
              onClick={(event) => {
                setRequestedTrack(item);
                pickTrack(event, item, i);
              }}
            >
              <span className="text-gray-100 text-lg font-black font-medium">
                {i + 1}.
              </span>
              <img
                className=" rounded-full object-cover mr-1"
                src={item.coverart}
                style={{ width: "48px" }}
                alt={item.track}
              />
              <div className="flex-1" style={{ width: "50%" }}>
                <h3 
                className={cn("text-lg font-medium text-white overflow-hidden", {
                  'text-black': mode === 'light',
                  'text-white': mode === 'dark',
                })}
                >
                  {item.artist}
                </h3>
                <p 
                className={cn("text-base", {
                  'text-black': mode === 'light',
                  'text-gray-100': mode === 'dark',
                })}
                >{item.track} </p>
                <p 
                className={cn("text-base", {
                  'text-black': mode === 'light',
                  'text-gray-100': mode === 'dark',
                })}
                >{item.genre} </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const TrackList = ({ tracks, setRequestedTrack, pickTrack, mode }) => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleItemClick = (index) => {
    // console.log("activeIndex", activeIndex);
    // console.log("genre clicked", index);
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // Group tracks by genre
  const groupedTracks = groupTracksByGenre(tracks);

  return (
    <div className="accordion-list">
      {Object.keys(groupedTracks).map((genre) => (
        <div
          key={genre}
          className="accordion-container"
        >
          <AccordionItem
            key={genre}
            genreType={genre}
            answer={groupedTracks[genre]}
            isOpen={activeIndex === genre}
            onClick={() => handleItemClick(genre)}
            setRequestedTrack={setRequestedTrack}
            pickTrack={pickTrack}
            mode={mode}
          />
        </div>
      ))}
    </div>
  );
};

// Utility function to group tracks by genre
const groupTracksByGenre = (tracks) => {
  return tracks.reduce((acc, track) => {
    if (!acc[track.genrekey]) {
      acc[track.genrekey] = [];
    }
    acc[track.genrekey].push(track);
    return acc;
  }, {});
};

const App = ({ data, pickTrack, setRequestedTrack, listTitle, mode }) => (
  <div className="">
    <h2 
      className={cn("", {
        'text-black': mode === 'light',
        'text-white': mode === 'dark',
      })}
    >
      {listTitle}
    </h2>
    <TrackList
      tracks={data}
      pickTrack={pickTrack}
      setRequestedTrack={setRequestedTrack}
      mode={mode}
    />
  </div>
);

export default App;
