const createGenreKey = (genre) => {
  //take all spacs and non alphnumeric characters out of genre
  let shortGenre = genre.replace(/\s/g, "");
  shortGenre = shortGenre.replace(/[^a-zA-Z0-9]/g, "");
  shortGenre = shortGenre.toLowerCase();
  return shortGenre;
};

export const groupTracksByGenre = (tracks) => {
  const genredtracks = [];
  tracks.forEach((item) => {
    if (item.genre.includes("/")) {
      let newGenres = item.genre.split("/");
      newGenres.forEach((newitem) => {
        genredtracks.push({
          coverart: item.coverart,
          artist: item.artist,
          track: item.track,
          genre: newitem,
          genrekey: createGenreKey(newitem),
        });
      });
    } else {
      item.genrekey = createGenreKey(item.genre);
      genredtracks.push(item);
    }
  });
  return genredtracks;
};
