import React, { useState, useRef, useEffect } from "react";

export const Avatar = ({ photoURL, message }) => {
  // console.log('message', message);
  const [userInfo, setUserInfo] = useState(null);

  // useEffect(() => {
  //   setUserInfo(avObj[uid]);
  // }, [avObj, uid]);

  // const FallBackAvatar = ({ userInfo }) => {
  //   // get some rando images and place first letter of either displayName or email
  //   let capLetter = "";
  //   // fallback avatar'
  //   if (
  //     userInfo &&
  //     userInfo !== null &&
  //     (userInfo.displayName !== undefined || userInfo.email !== undefined)
  //   ) {
  //     capLetter =
  //       userInfo.displayName !== undefined
  //         ? userInfo.displayName.charAt(0) || ""
  //         : userInfo.email.charAt(0) || "";
  //   }
  //   return <div style={{ textTransform: "capitalize" }}>{capLetter}</div>;
  // };

  return (
    <div>
      {photoURL ? (
        <img
          src={photoURL}
          alt={
            message.displayName !== undefined
              ? message.displayName.charAt(0)
              : message.email.charAt(0)
          }
          title={message.displayName}
        />
      ) : (
        <>
        <div>{message.displayName}</div>
        </>
        // <FallBackAvatar userInfo={userInfo} />
      )}
    </div>
  );
};
