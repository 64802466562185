import axios from 'axios';

export default axios.create({
	baseURL: 'https://www.googleapis.com/youtube/v3',
	params: {
		part: 'snippet',
		type: 'video',
		maxResults: 5,
		key: 'AIzaSyBa67S1ozen4ODOlPUm2d5N2r8isE-PZhs', //'AIzaSyAaGVGPCShsFPzcelLw5slKBV5Wa8FhYHc' //process.env.REACT_APP_KEY
	},
});