// import styles from "./Play.css";
import { createGlobalStyle } from "styled-components";
import React, { useState, useEffect, useRef } from "react";
// import toast, { Toaster } from "react-hot-toast";
import classNames from "classnames";

// import PageTemplate from "./PageTemplate";
import PlayerTemplate from "./PlayerTemplate";
import PlayerPanel from "./PlayerPanel";
import Title from "./Title";
import CoverImage from "./CoverImage";
import Time from "./audio-player/Time";
import Progress from "./audio-player/Progress";
import ButtonsBox from "./audio-player/ButtonsBox";
import Volume from "./Volume";

import Scrollbars from "react-custom-scrollbars-2";

// const colors = `html{
//     --tagsBackground: #13781c;
//     --tagsText: #ffffff;
//     --tagsBackgroundHoverActive: #2cc0a0;
//     --tagsTextHoverActive: #ffffff;
//     --searchBackground: #18191f;
//     --searchText: #ffffff;
//     --searchPlaceHolder: #575a77;
//     --playerBackground: #18191f;
//     --titleColor: #ffffff;
//     --timeColor: #ffffff;
//     --progressSlider: #13781c;
//     --progressUsed: #ffffff;
//     --progressLeft: #151616;
//     --volumeSlider: #13781c;
//     --volumeUsed: #ffffff;
//     --volumeLeft:  #151616;
//     --playlistBackground: #18191f;
//     --playlistText: #575a77;
//     --playlistBackgroundHoverActive:  #18191f;
//     --playlistTextHoverActive: #ffffff;
//   }`;

// const GlobalStyles = createGlobalStyle`
// ${colors}
// `;

// const Scrollbars = dynamic(() => import("react-custom-scrollbars-2"), {
//   ssr: false,
// });

// const notify = (msg1, msg2) =>
//   toast.custom(
//     (t) => (
//       <div
//         className={classNames([
//           styles.notificationWrapper,
//           t.visible ? "top-0" : "-top-96",
//         ])}
//       >
//         <div className={styles.iconWrapper}>LightningBoltIcon</div>
//         <div className={styles.contentWrapper}>
//           <h1>{msg1}</h1>
//           <p>{msg2}</p>
//         </div>
//         <div className={styles.closeIcon} onClick={() => toast.dismiss(t.id)}>
//           X
//         </div>
//       </div>
//     ),
//     { id: "unique-notification", position: "top-center" }
//   );

const Player = ({
  trackList,
  clearList,
  jukebox,
  includeTags = true,
  includeSearch = true,
  showPlaylist = true,
  autoPlayNextTrack = true,
  //customColorScheme = colors,
}) => {
  const { message, setMessage } = useState(""); //(Message_data);
  // const router = useRouter();
  const [query, updateQuery] = useState("");

  // let playlist = [];

  const [audio, setAudio] = useState(null);
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [releasePath, setReleasePath] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [length, setLength] = useState(0);
  const [time, setTime] = useState(0);
  const [slider, setSlider] = useState(1);
  const [drag, setDrag] = useState(0);
  const [volume, setVolume] = useState(0.8);
  let [end, setEnd] = useState(0);
  const [shuffled, setShuffled] = useState(false);
  const [looped, setLooped] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [aboutToggle, setAboutToggle] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [allTrackList, setAllTrackList] = useState([]);
  const [loadAllTracks, setLoadAllTracks] = useState([]);
  const [firstTimePlaylist, setFirstTimePlaylist] = useState(true);
  const [listNotFull, setListNotFull] = useState(true);
  const [playlist, setPlaylist] = useState([]);

  const [filter, setFilter] = useState([]);
  let [curTrack, setCurTrack] = useState(0);

  const fmtMSS = (s) => new Date(1000 * s).toISOString().substr(15, 4);

  useEffect(() => {
    setTimeout(() => {
      const audio = new Audio(trackList[curTrack].url);
      const setAudioData = () => {
        setLength(audio.duration);
        setTime(audio.currentTime);
      };

      const setAudioTime = () => {
        const curTime = audio.currentTime;
        setTime(curTime);
        setSlider(curTime ? ((curTime * 100) / audio.duration).toFixed(1) : 0);
      };

      const setAudioVolume = () => setVolume(audio.volume);
      const setAudioEnd = () => setEnd((end += 1));

      // events on audio object
      audio.addEventListener("loadeddata", setAudioData);
      audio.addEventListener("timeupdate", setAudioTime);
      audio.addEventListener("volumechange", setAudioVolume);
      audio.addEventListener("ended", setAudioEnd);

      setAudio(audio);
      setCoverImage(trackList[curTrack].coverImage);
      setTitle(trackList[curTrack].title);
      setArtist(trackList[curTrack].artist);
      setReleasePath(trackList[curTrack].releasePath);
      const pl = []
      const jb = jukebox.map((item, index) => {
        // console.log('index', index)
        pl.push(index)
        return {
          url:
            item.url ||
            "https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FEbu%20Blackitude%20-%20Something%20Black.mp3?alt=media&token=5926ab41-e9ee-49f6-9a4b-048415d224b5",
          title: item.track,
          artist: item.artist,
          releasePath: "XXXX",
          tags: item.genre,
          coverImage: item.coverart,
          userplaylist: false,
        };
      });

      setPlaylist(pl);
      // console.log('***** playlist', pl)
      trackList.push(...jb);

      return () => {
        audio.pause();
      };
    }, "1000");
  }, []);

  useEffect(() => {
    const playerControlData = async () => {
      if (message) {
        const data = JSON.parse(message);
        if (data.url) {
          trackList.push(data);
          setCurTrack(trackList.length - 1);
          setCoverImage(trackList[curTrack].coverImage);
        }
        if (data.playlist) {
          const playlistData = JSON.parse(data.playlist);
          // filter out only audio products
          //console.log('playlistData', playlistData)
          setAllTrackList(playlistData);
          //trackList.push(...playlistData);
        }
        if (data.userplaylist) {
          const userplaylistData = JSON.parse(data.userplaylist);
          if (
            userplaylistData &&
            (data.notRandom == true || firstTimePlaylist)
          ) {
            trackList.push(...userplaylistData);
            setFirstTimePlaylist(false);
          }
        }
        if (data.clearplaylist) {
          trackList.splice(0, trackList.length);
          setListNotFull(true);
        }
        if (data.faveplaylist) {
          const faveplaylistData = JSON.parse(
            JSON.stringify(data.faveplaylist)
          );
          trackList.unshift(...faveplaylistData);
        }
        if (data.aboutpanel) {
          setAboutToggle(false);
        }
        if (data.menupanel) {
          setMenuToggle(false);
        }
        if (data.play) {
          const track_num = parseInt(JSON.parse(data.play));
          playlistItemClick(track_num);
        }
        if (data.pause) {
          pause();
        }
        if (data.userloggedin) {
          const userloggedin = JSON.parse(data.userloggedin);
          // notify(
          //   "CTFO Notification",
          //   "User: " + userloggedin.user.email + " logged in"
          // );
        }
        if (data.userloggedout) {
          const userloggedout = JSON.parse(data.userloggedout);
          // notify(
          //   "CTFO Notification",
          //   "User: " + userloggedout.user + " logged out"
          // );
        }
        if (data.checkout) {
          // notify("CTFO Notification", "Contacting Checkout, please wait...");
        }
        if (data.checkoutcancelled) {
          // notify("CTFO Notification", "order cancelled...");
        }
        if (data.manageplaylists) {
          manageplaylists();
        }
        if (data.removeFromPlaylist) {
          const removeFromPlaylist_ID = JSON.parse(
            JSON.stringify(data.removeFromPlaylist)
          );
          // console.log("removeFromPlaylist_ID", removeFromPlaylist_ID);
          const i = trackList.findIndex(
            (item) => item.id == removeFromPlaylist_ID
          );
          if (i > -1) trackList.splice(i, 1);
        }
        if (data.loginPrompt) {
          // notify("CTFO Notification", "login to add track to playlist");
        }
      }
    };
    playerControlData();
  }, [message]);

  const tags = [];
  // trackList.forEach((track) => {
  //   track.tags.forEach((tag) => {
  //     if (!tags.includes(tag)) {
  //       tags.push(tag);
  //     }
  //   });
  // });

  const shufflePlaylist = (arr) => {
    if (arr.length === 1) return arr;
    const rand = Math.floor(Math.random() * arr.length);
    return [arr[rand], ...shufflePlaylist(arr.filter((_, i) => i != rand))];
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (shuffled) {
        playlist = shufflePlaylist(playlist);
      }
      !looped && autoPlayNextTrack ? next() : play();
    }
  }, [end]);

  useEffect(() => {
    if (audio != null) {
      audio.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audio != null) {
      pause();
      const val = Math.round((drag * audio.duration) / 100);
      audio.currentTime = val;
    }
  }, [drag]);

  const play = () => {
    setActive(true);
    audio.play();
  };

  const pause = () => {
    setActive(false);
    // setMessage(JSON.stringify({ pause: true }));
    audio.pause();
  };

  const loop = () => {
    setLooped(!looped);
  };

  // const home = () => {
  //   router.push("/");
  // };

  const about = () => {
    if (toggle) setToggle(!toggle);
    if (menuToggle) setMenuToggle(!menuToggle);
    setAboutToggle(!aboutToggle);
  };

  const manageplaylists = () => {
    if (aboutToggle) setAboutToggle(!aboutToggle);
    if (menuToggle) setMenuToggle(!menuToggle);
    setToggle(!toggle);
  };

  const basket = () => {
    // navigate to basket page
    //console.log("navigate to basket");
  };

  const menu = () => {
    if (aboutToggle) setAboutToggle(!aboutToggle);
    if (toggle) setToggle(!toggle);
    setMenuToggle(!menuToggle);
  };

  const handleClick = () => {
    if (aboutToggle) setAboutToggle(!aboutToggle);
    if (menuToggle) setMenuToggle(!menuToggle);
    setToggle(!toggle);
  };

  useEffect(() => {
    // console.log("curTrack", curTrack);
    // console.log("trackList", trackList);
    if (audio != null) {
      audio.src = trackList[curTrack].url;
      setCoverImage(trackList[curTrack].coverImage);
      setTitle(trackList[curTrack].title);
      setArtist(trackList[curTrack].artist);
      setReleasePath(trackList[curTrack].releasePath);
      play();
    }
  }, [curTrack]);

  const previous = () => {
    const index = playlist.indexOf(curTrack);
    index !== 0
      ? setCurTrack((curTrack = playlist[index - 1]))
      : setCurTrack((curTrack = playlist[playlist.length - 1]));
  };

  const next = () => {
    // console.log('curTrack', curTrack)
    const index = playlist.indexOf(curTrack);
    // console.log('index', index)
    // console.log('playlist', playlist)
    // const index = jukeboxPlaylist.indexOf(curTrack);
    index !== playlist.length - 1
      ? setCurTrack((curTrack = playlist[index + 1]))
      : setCurTrack((curTrack = playlist[0]));
  };

  const shuffle = () => {
    setShuffled(!shuffled);
  };

  const playlistItemClickHandler = (e) => {
    const num = Number(e.currentTarget.getAttribute("data-key"));
    const index = playlist.indexOf(num);
    setCurTrack((curTrack = playlist[index]));
    play();
  };

  const playlistItemClick = (num) => {
    const index = playlist.indexOf(num);
    setCurTrack((curTrack = playlist[index]));
    play();
  };

  const isInitialFilter = useRef(true);
  useEffect(() => {
    if (isInitialFilter.current) {
      isInitialFilter.current = false;
    } else {
      if (!playlist.includes(curTrack)) {
        setCurTrack((curTrack = playlist[0]));
      }
    }
  }, [filter]);

  const tagClickHandler = (e) => {
    const tag = e.currentTarget.innerHTML;
    if (!filter.includes(tag)) {
      setFilter([...filter, tag]);
    } else {
      const filteredArray = filter.filter((item) => item !== tag);
      setFilter([...filteredArray]);
    }
  };

  // click on search bar
  const forceUpdate = React.useCallback(() => setLoadAllTracks({}), []);
  const handleSearchClick = () => {
    // console.log("allTrackList", allTrackList);
    // console.log("listNotFull", listNotFull);
    if (allTrackList && listNotFull) {
      trackList.push(...allTrackList);
      setListNotFull(false);
    }
    forceUpdate();
  };
  const ebz = true;

  return (
    <div className="flex flex-row justify-start w-full">
      {/* <GlobalStyles /> */}
      {/* <PlayerPanel toggle={toggle}> */}
      {/* <XIcon
          className="absolute top-7 right-0 cursor-pointer w-8 h-10 hover:fill-teal-500"
          onClick={handleClick}
        /> */}
      {/* <div onClick={handleClick}>XXX</div> */}
      {/* <div className=""> */}
      {/* grid grid-cols-12 gap-3 */}
      {/* <div className="col-span-6 sm:text-sm md:text-sm lg:text-sm menusocial"> */}
      {/* <Scrollbars style={{ width: "200%", height: "calc(100vh - 90px)" }}> */}
      {/* <h2 >
                CTFO MUSIC PLAYLIST
                logo
              </h2> */}
      {/* {includeTags && (
                <TagsTemplate>
                  {tags.map((tag, index) => {
                    return (
                      <TagItem
                        key={index}
                        className={
                          filter.length !== 0 && filter.includes(tag)
                            ? "active"
                            : ""
                        }
                        tag={tag}
                        onClick={tagClickHandler}
                      />
                    );
                  })}
                </TagsTemplate>
              )} */}
      {/* {showPlaylist && (
                <>
                  <LoadPlaylists />
                </>
              )} */}
      {/* {includeSearch && (
                <Search
                  value={query}
                  onChange={(e) => updateQuery(e.target.value.toLowerCase())}
                  placeholder={`Search ${trackList.length} tracks...`}
                  onClick={() => handleSearchClick()}
                />
              )} */}
      {/* {showPlaylist && (
                <>
                  <PlaylistTemplate>
                    {trackList
                      //.sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((el, index) => {
                        if (
                          filter.length === 0 ||
                          filter.some((filter) => el.tags.includes(filter))
                        ) {
                          if (
                            el.title.toLowerCase().includes(query.toLowerCase())
                          ) {
                            playlist.push(index);
                            return (
                              <PlaylistItem
                                className={curTrack === index ? "active" : ""}
                                key={index}
                                data_key={index}
                                title={el.title}
                                id={el.id}
                                src={el.url}
                                coverImage={el.coverImage}
                                onClick={playlistItemClickHandler}
                              />
                            );
                          }
                        }
                      })}
                  </PlaylistTemplate>
                </>
              )} */}
      {/* </Scrollbars> */}
      {/* </div> */}
      {/* <div className=""></div> */}
      {/* </div> */}
      {/* </PlayerPanel> */}
      {/* <AboutPanel toggle={aboutToggle} /> */}
      {/* <MenuPanel toggle={menuToggle}></MenuPanel> */}
      {/* <PlayerTemplate> */}
      <div className="flex flex-row justify-start items-center">
        <div onClick={handleClick}>
          {toggle ? (
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                fill="white"
              ></path>{" "}
            </svg>
          ) : (
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-up"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                fill-rule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                fill="white"
              ></path>{" "}
            </svg>
          )}
        </div>
        {/* <CTFOLogo src={ctfoBtn} onClick={home} /> */}
        <CoverImage src={coverImage} />
        <Title title={title} artist={artist} releasePath={releasePath} />
        <div className="flex flex-row justify-start items-baseline md:items-center">
          <div onClick={loop} className="pl-2">
            {looped ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                viewBox="0 0 16 16"
                id="IconChangeColor"
              >
                {" "}
                <path
                  d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"
                  id="mainIconPathAttribute"
                  fill="#737373"
                ></path>{" "}
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                  id="mainIconPathAttribute"
                  fill="#737373"
                ></path>{" "}
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "white" }}
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-arrow-repeat"
                viewBox="0 0 16 16"
                id="IconChangeColor"
              >
                {" "}
                <path
                  d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"
                  id="mainIconPathAttribute"
                  fill="#737373"
                ></path>{" "}
                <path
                  fill-rule="evenodd"
                  d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                  id="mainIconPathAttribute"
                  fill="#737373"
                ></path>{" "}
              </svg>
            )}
          </div>
          <div onClick={previous} className="pl-2">
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-skip-backward-fill"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="M.5 3.5A.5.5 0 0 0 0 4v8a.5.5 0 0 0 1 0V8.753l6.267 3.636c.54.313 1.233-.066 1.233-.697v-2.94l6.267 3.636c.54.314 1.233-.065 1.233-.696V4.308c0-.63-.693-1.01-1.233-.696L8.5 7.248v-2.94c0-.63-.692-1.01-1.233-.696L1 7.248V4a.5.5 0 0 0-.5-.5z"
                fill="white"
              ></path>{" "}
            </svg>
          </div>
          {active ? (
            <div onClick={pause} className="pl-2">
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                {" "}
                <path
                  d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"
                  fill="white"
                ></path>{" "}
              </svg>
            </div>
          ) : (
            <div onClick={play} className="pl-2">
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                {" "}
                <path
                  d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                  fill="white"
                ></path>{" "}
              </svg>
            </div>
          )}
          <div onClick={next} className="pl-2">
            <svg
              style={{ color: "white" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-skip-forward-fill"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.753l-6.267 3.636c-.54.313-1.233-.066-1.233-.697v-2.94l-6.267 3.636C.693 12.703 0 12.324 0 11.693V4.308c0-.63.693-1.01 1.233-.696L7.5 7.248v-2.94c0-.63.693-1.01 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5z"
                fill="white"
              ></path>{" "}
            </svg>
          </div>
          <div onClick={shuffle} className="pl-2">
            {shuffled ? (
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-shuffle"
                viewBox="0 0 16 16"
                id="IconChangeColor"
              >
                {" "}
                <path
                  fill-rule="evenodd"
                  d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"
                  id="mainIconPathAttribute"
                  fill="#737373"
                ></path>{" "}
                <path
                  d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"
                  id="mainIconPathAttribute"
                  fill="#737373"
                ></path>{" "}
              </svg>
            ) : (
              <svg
                style={{ color: "white" }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-shuffle"
                viewBox="0 0 16 16"
              >
                {" "}
                <path
                  fill-rule="evenodd"
                  d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.624 9.624 0 0 0 7.556 8a9.624 9.624 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.595 10.595 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.624 9.624 0 0 0 6.444 8a9.624 9.624 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5z"
                  fill="white"
                ></path>{" "}
                <path
                  d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192zm0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192z"
                  fill="white"
                ></path>{" "}
              </svg>
            )}
          </div>
        </div>
        <Time time={`${!time ? "0:00" : fmtMSS(time)}`} />
        <Progress
          value={slider}
          onChange={(e) => {
            setSlider(e.target.value);
            setDrag(e.target.value);
          }}
          onMouseUp={play}
          onTouchEnd={play}
        />
        <Time time={`${!length ? "0:00" : fmtMSS(length)}`} />
        <Volume
          value={volume}
          onChange={(e) => {
            setVolume(e.target.value / 100);
          }}
        />
      </div>
      {/* </PlayerTemplate> */}
      {/* <div
        className={classNames([
          styles.notificationWrapper,
          ebz ? "-top-96" : "-top-96",
        ])}
      >
        <div className={styles.iconWrapper}>
          h
        </div>
        <div className={styles.contentWrapper}>
          <h1>highlight</h1>
          <p>
            message
          </p>
        </div>
        <div className={styles.closeIcon} onClick={() => toast.dismiss(t.id)}>
          X
        </div>
      </div> */}
      {/* <Toaster /> */}
    </div>
  );
};

export default Player;
