import React, { useState, useRef, useEffect } from "react";
import { db, auth } from "../firebase-config";
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
// import { useAuth } from "../context/AuthUserContext";
import { Avatar } from "./Avatar";

export const UserView = ({ userchat, avatars, isUserViewOpen }) => {
    
  return (
    <>
      <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-panel">
        <div
          className="py-2 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefaultButton"
        >
          <div className="text-center">
            <div
              onClick={() => {
                isUserViewOpen(false);
              }}
              class="muuzbox-section-button"
            >
              <div
                id="notifyMuuzbox-close"
                class="section-close muuzbox-close rounded"
              ></div>
            </div>

            <button
              onClick={() => {
                isUserViewOpen(false);
              }}
              className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
            >
              <div>
                <div className="grid justify-items-center">
                  <Avatar avObj={avatars} uid={userchat.uid} />
                </div>
                <div> {userchat.users[1].displayName}</div>
                {/* <strong>XXX</strong> */}
              </div>
              {/* <div>XXXX</div> */}
            </button>
            {/* <div className="block w-full text-xl text-center text-red-800">
              DJ OBAH has been informed of your request
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
