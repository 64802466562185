import React, { useState, useEffect } from "react";
import "../styles/app.scss";
// import Contact from './Contact';
import SearchBar from "./SearchBar";
import VideoList from "./VideoList";
import VideoDetail from "./VideoDetail";
import useVideos from "../hooks/useVideos";

const MuuzTubeSearch = ({ acceptedSong, vtp }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, search] = useVideos(
    acceptedSong.request.artist + " " + acceptedSong.request.track || null
  );

  const videoToPlaylist = (video) => {
    vtp(video);
  };

  // console.log('acceptedSong', acceptedSong)

  useEffect(() => {
    setSelectedVideo(videos[0]);
  }, [videos]);

  return (
    <div className="youtube-search-page">
      <div className="background"></div>
      <SearchBar onFormSubmit={search} />
      <div className="video-content-section">
        <div className="video-player-container">
          <VideoDetail
            video={selectedVideo}
            videoToPlaylist={videoToPlaylist}
          />
        </div>
        <div className="video-previews-container">
          <VideoList onVideoSelect={setSelectedVideo} videos={videos} />
        </div>
      </div>
      {/* <Contact /> */}
    </div>
  );
};

export default MuuzTubeSearch;
