import React, { useState, useEffect } from "react";
import { db, db2, auth } from "../firebase-config";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  collection,
  onSnapshot,
  query,
  serverTimestamp,
  where,
  orderBy,
} from "firebase/firestore";
import { groupTracksByGenre } from "../lib/grouptracksByGenre"
import AccordionList from "./AccordionList";
import "../styles/Chat.scss";

export const Jukebox = ({ userObj }) => {
  const [jukebox, setJukebox] = useState([]);
  const jukeboxRef = collection(db2, "jukebox");
  const [inputFocus, setInputFocus] = useState(false);
  const [originalJukebox, setOriginalJukebox] = useState([]);
  const [count, setCount] = useState(originalJukebox.length);
  const [isOpen, setIsOpen] = useState(false);
  const [displayNameOpen, setDisplayNameOpen] = useState(false);
  const [requestedTrack, setRequestedTrack] = useState({});
  const [DJChatId, setDJChatId] = useState("5fD03BaD7INyhJMne2gfLdPAKbQ2");
  const [messages, setMessages] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [groupedTracks, setGroupedTracks] = useState([]);
  const [newDisplayName, setNewDisplayName] = useState("");
  const [jukeboxUserObj, setJukeboxUserObj] = useState(userObj);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});

  useEffect(() => {
    const queryMessages = query(
      jukeboxRef /*,
      where("room", "==", room),
      orderBy("createdAt")*/
    );
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let jukebox = [];
      snapshot.forEach((doc) => {
        jukebox.push({ ...doc.data(), id: doc.id });
      });
      //sort jukebox list into groups of lists
      setGroupedTracks(groupTracksByGenre(jukebox[0].jukeboxList));
      setJukebox(jukebox[0].jukeboxList);
      setOriginalJukebox(jukebox[0].jukeboxList);
    });

    return () => unsuscribe();
  }, []);

  useEffect(() => {
    setJukeboxUserObj(userObj);
  }, [userObj]);

  // const createGenreKey = (genre) => {
  //   //take all spacs and non alphnumeric characters out of genre
  //   let shortGenre = genre.replace(/\s/g, "");
  //   shortGenre = shortGenre.replace(/[^a-zA-Z0-9]/g, "");
  //   shortGenre = shortGenre.toLowerCase();
  //   return shortGenre;
  // };

  // const groupTracksByGenre = (tracks) => {
  //   const genredtracks = [];
  //   tracks.forEach((item) => {
  //     if (item.genre.includes("/")) {
  //       let newGenres = item.genre.split("/");
  //       newGenres.forEach((newitem) => {
  //         genredtracks.push({
  //           coverart: item.coverart,
  //           artist: item.artist,
  //           track: item.track,
  //           genre: newitem,
  //           genrekey: createGenreKey(newitem),
  //         });
  //       });
  //     } else {
  //       item.genrekey = createGenreKey(item.genre);
  //       genredtracks.push(item);
  //     }
  //   });
  //   return genredtracks;
  // };

  useEffect(() => {
    const chatsMessgesRef = collection(db, "djchats", DJChatId, "messages");
    const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
    const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
      let messagesx = [];
      let avatars = [];

      snapshot.forEach((doc) => {
        // create an avatar list that can be referred to when the chatlist is rendered
        avatars.push(doc.data().uid);
        messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
      });

      new Promise(function (resolve, reject) {
        const avatarObj = {};
        const uniq = [...new Set(avatars)];
        let i = 0;
        // search for these uids and the photoURL that goes with them
        uniq.forEach(async (uid) => {
          i++;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          console.log("uid", uid);
          console.log("docSnap.data()", docSnap.data());
          if (docSnap.exists()) {
            avatarObj[uid] = {
              displayName: docSnap.data().displayName,
              photoURL: docSnap.data().photoURL,
              email: docSnap.data().email,
            };
          } else {
            // some random image maybe an ai generated icon and displayname
            avatarObj[uid] = {
              displayName: docSnap.data().displayName || "",
              photoURL: docSnap.data().photoURL || "",
            };
          }
        });
        if (i >= uniq.length) resolve(avatarObj);
        else reject();
      })
        .then(function (avatarObj) {
          return new Promise((resolve, reject) => {
            setTimeout(() => resolve(setAvatars(avatarObj)), 300);
          });
        })
        .then(function (result) {
          setMessages(messagesx);
          // setTimeout(() => {
          //   updateWatchList(messagesx.length - 1);
          //   // update
          // }, 2000);
        });
    });
    return () => unsuscribe();
  }, [DJChatId]);

  const checkUserPickInList = (item) => {
    console.log("checkUserPickInList", item.uid);
    // check if user has already picked a song in the last 20 minutes
    const requestFeedbackRef = collection(db, "djchats");
    const queryMessages = query(
      requestFeedbackRef,
      where("uid", "==", item.uid),
      orderBy("createdAt")
    );
    onSnapshot(queryMessages, (snapshot) => {
      let requestFeedback = [];
      snapshot.forEach((doc) => {
        const thisItemtimeStamp = doc.data().createdAt;
        console.log("thisItemtimeStamp", thisItemtimeStamp.seconds);
        const timestampInSeconds = (Date.now() / 1000) | 0;
        console.log("timestampInSeconds", timestampInSeconds);
        console.log("how to convert these and substract 20 minutes");

        if (timestampInSeconds - thisItemtimeStamp.seconds < 1200) {
          console.log(
            "you have already requested a song in the last 20 minutes"
          );
        } else {
          requestFeedback.push({ ...doc.data(), id: doc.id });
        }
      });
      console.log("requestFeedback", requestFeedback);
    });
  };

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      if (!value.track) value.track = value.artist;
      if (!value.artist) value.artist = value.track;
      if (!value.artist && !value.track) return null;
      return (
        value.track.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.artist.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleSubmit = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      let search = await arraySearch(jukebox, value);
      setJukebox(search);
      setCount(search.length);
    } else {
      setJukebox(originalJukebox);
      setCount(originalJukebox.length);
    }
    // console.log("sEaRCh UsErS", jukebox);
  };

  const handleSubmitDisplayname = async () => {
    if (newDisplayName.length > 2 || newDisplayName.length === 0) {
      if (newDisplayName.length === 0) {
        console.log("userObj", userObj);
        setNewDisplayName(userObj.displayName);
      }
      const ref = doc(db, `users`, auth.currentUser.uid);
      updateDoc(ref, {
        displayName: newDisplayName,
        nameChanged: true,
      });
      const newObj = jukeboxUserObj;
      newObj.displayName = newDisplayName;
      newObj.nameChanged = true;
      setJukeboxUserObj(newObj);
      setDisplayNameOpen(false);
      setIsOpen(!isOpen);
      // now send this request to dj
      sendThisPickToDJ(currentRequestDetails);
    } else {
      alert("new name needs to be longer");
    }
  };

  const handleUpdateDisplayname = async (event) => {
    let value = event.target.value;
    if (value.length > 2) {
      setNewDisplayName(value);
    }
  };

  const pickTrack = async (event, item, i) => {
    if (isOpen) {
      setIsOpen(!isOpen);
    } else {
      if (auth.currentUser.isAnonymous) {
        setIsOpen(false);
        console.log("jukeboxUserObj", jukeboxUserObj);
        if (!jukeboxUserObj.nameChanged) setDisplayNameOpen(true);
        else {
          setIsOpen(!isOpen);
          sendThisPickToDJ(item);
        }
        // lets check what the user name is
        console.log("item", item);
        console.log("compare this to freshly entered user name");
        setCurrentRequestDetails(item);
      } else {
        setIsOpen(!isOpen);
        sendThisPickToDJ(item);

        // const user = auth.currentUser;
        // const uid = user.uid;
        // const pickedItem = {
        //   request: item,
        //   createdAt: serverTimestamp(),
        //   user: auth.currentUser.displayName || auth.currentUser.email,
        //   uid: auth.currentUser.uid,
        // }
        //checkUserPickInList(pickedItem);

        // read the whole request list to find if this is already in it
        // request.track request.artist

        // requestList.forEach((request) => {
        //   console.log("request", request.request.track, request.request.artist);
        //   if (
        //     request.request.track === item.track &&
        //     request.request.artist === item.artist
        //   ) {
        //     console.log("this song has already been requested");
        //   }

        //   console.log(
        //     "request.createdAt.seconds in list",
        //     Math.floor(Date.now() / 1000) - request.createdAt.seconds
        //   );
        //   if (Math.floor(Date.now() / 1000) - request.createdAt.seconds < 1201) {
        //     console.log(
        //       "this user has already requested a song in the last 20 minutes"
        //     );
        //   }
        // });

        // const requestSongRef4 = collection(db, "djchats");
        // try {
        //   const newprod = await addDoc(requestSongRef4, {
        //     request: item,
        //     createdAt: serverTimestamp(),
        //     user: auth.currentUser.displayName || auth.currentUser.email,
        //     uid: auth.currentUser.uid,
        //   });
        //   console.log("djchat document written with ID: ", newprod.id);
        //   setDJChatId(newprod.id);
        //   const requestSongRef5 = collection(
        //     db,
        //     "djchats",
        //     newprod.id,
        //     "requesters"
        //   );
        //   try {
        //     const newrequester = await addDoc(requestSongRef5, {
        //       createdAt: serverTimestamp(),
        //       user: auth.currentUser.displayName || auth.currentUser.email,
        //       uid: auth.currentUser.uid,
        //     });
        //     console.log("newrequester id: ", newrequester.id);
        //   } catch (error) {
        //     console.error("Error adding document: ", error);
        //   }

        //   const requestSongRef6 = collection(
        //     db,
        //     "users",
        //     auth.currentUser.uid,
        //     "requests"
        //   );
        //   try {
        //     const userrequest = await addDoc(requestSongRef6, {
        //       createdAt: serverTimestamp(),
        //       djchatId: newprod.id,
        //     });
        //     console.log("userrequest id: ", userrequest.id);
        //   } catch (error) {
        //     console.error("Error adding document: ", error);
        //   }
        // } catch (error) {
        //   console.error("Error adding document: ", error);
        // }

        /*
    this user can now specifically wait for updates to the above record from the dj app
    as it has their uid as its id in djchats collection
    */
      }
    }
  };

  const getCurrentDisplayName = async () => {
    try {
      const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
      const user = await getDoc(requestCurrentIdRef, requestCurrentIdRef);
      return user.displayName;
    } catch (err) {
      console.log("error:", err);
    }
  };

  const sendThisPickToDJ = async (item) => {
    let prepareOpenRequest = new Promise(function (resolve, reject) {
      resolve("Promise resolved");
    });

    prepareOpenRequest
      .then(async () => {
        try {
          const requestCurrentIdRef = doc(db, "users", auth.currentUser.uid);
          const user = await getDoc(requestCurrentIdRef, requestCurrentIdRef);
          if (user.exists()) {
            const displayName = user.data().displayName;
            return displayName;
          }
        } catch (err) {
          console.log("error:", err);
        }
      })
      .then(async (displayName) => {
        console.log("displayName", displayName);
        console.log("newDisplayName", newDisplayName);
        const requestSongRef4 = collection(db, "djchats");
        if (newDisplayName !== "") displayName = newDisplayName;
        try {
          const newprod = await addDoc(requestSongRef4, {
            request: item,
            createdAt: serverTimestamp(),
            user: displayName,
            uid: auth.currentUser.uid,
          });
          setDJChatId(newprod.id);
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef5 = collection(
          db,
          "djchats",
          newprod.id,
          "requesters"
        );
        try {
          await addDoc(requestSongRef5, {
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName || auth.currentUser.email,
            uid: auth.currentUser.uid,
          });
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef5 = collection(
          db,
          "djchats",
          newprod.id,
          "requesters"
        );
        try {
          await addDoc(requestSongRef5, {
            createdAt: serverTimestamp(),
            user: auth.currentUser.displayName || auth.currentUser.email,
            uid: auth.currentUser.uid,
          });
          return newprod;
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      })
      .then(async (newprod) => {
        const requestSongRef6 = collection(
          db,
          "users",
          auth.currentUser.uid,
          "requests"
        );
        try {
          await addDoc(requestSongRef6, {
            createdAt: serverTimestamp(),
            djchatId: newprod.id,
          });
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      });
  };

  const setRequestedTrackSetter = (item) => {
    setRequestedTrack(item);
  };

  return (
    <>
      <div className="flex flex-col flex-auto sm:items-stretch md:item-stretch jukebox-panel eebbzz">
        {/* DJ / User details */}
        <div className="flex flex-row text-white items-center pb-3 font-black">
          <div className="text-sm mx-2 my-1">
            DJ OBAH<p className="text-gray-100 text-base">Muuzbox</p>
          </div>

          <div class="flex justify-center h-16 w-16 md:h-16 md:w-16 max-h-16 max-w-16 mx-0.5 my-0.5 rounded-full border overflow-hidden text-center items-center ">
            <div>
              <div className="dj-wc-logo"></div>
            </div>
          </div>
          <div className="text-center mx-2 my-1">x</div>
          <div class="flex justify-center h-16 w-16 md:h-16 md:w-16 max-h-16 max-w-16 mx-0.5 my-0.5 rounded-full border overflow-hidden text-center items-center ">
            <div>
              <img
                src={jukeboxUserObj.photoURL}
                alt={jukeboxUserObj.displayNamel}
                title={jukeboxUserObj.displayName}
              />
            </div>
          </div>

          <div className="text-sm mx-2 my-1">
            {jukeboxUserObj.displayName}
            <p className="text-gray-100 text-base">Member</p>
          </div>
        </div>
        <div className="text-white text-center font-black text-xs uppercase pb-2">
          Playlist
        </div>

        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 panel-fade">
          <div
            className={`flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 h-24 text-enter-fit ${
              inputFocus === true ? "text-enter-full" : ""
            }`}
          >
            <div className="flex-grow ml-2 h-20">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="search jukebox"
                  onFocus={() => setInputFocus(true)}
                  onChange={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSubmit(e);
                  }}
                  className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                />
              </div>
            </div>
            <div className="ml-2">
              <button
                onClick={(event) => handleSubmit(event)}
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-4 h-8"
              >
                <span className="ml-0">
                  <svg
                    className="w-4 h-4 transform rotate-45 -mt-px"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    ></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>

          <div className="flex flex-col overflow-x-auto mb-4 scrollable-section">
            <div className="flex flex-row  h-full">
              <div className="muuzbox-list">
                {displayNameOpen && (
                  <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-displayname-panel">
                    <div>
                      CURRENT DISPLAY NAME
                      <input
                        placeholder={userObj.displayName}
                        type="text"
                        name="newdisplayname"
                        id="newdisplayname"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSubmitDisplayname(e);
                        }}
                        onChange={handleUpdateDisplayname}
                        className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                      />
                      <button
                        onClick={(e) => {
                          setDisplayNameOpen(false);
                          handleSubmitDisplayname(e);
                        }}
                        className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 muuzmenuitem"
                      >
                        update your name to continue
                      </button>
                    </div>
                  </div>
                )}
                {isOpen && (
                  <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-panel">
                    <div
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <div className="text-center">
                        <div
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          class="muuzbox-section-button"
                        >
                          <div
                            id="notifyMuuzbox-close"
                            class="section-close muuzbox-close rounded"
                          ></div>
                        </div>

                        <button
                          onClick={() => {
                            setIsOpen(!isOpen);
                          }}
                          className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                        >
                          <div>
                            <strong>{requestedTrack.artist}</strong>
                          </div>
                          <div>{requestedTrack.track}</div>
                        </button>
                        <div className="block w-full text-xl text-center text-red-800">
                          DJ OBAH has been informed of your request
                        </div>
                      </div>

                      {messages && (
                        <>
                          {messages.map((message, i) => (
                            <div
                              className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                              key={i}
                            >
                              <div className="flex items-center justify-center h-12 w-12 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0">
                                <img
                                  src={avatars[message.uid]?.photoURL}
                                  alt={avatars[message.uid]?.displayName}
                                />
                              </div>
                              <div className="ml-2 text-lg font-semibold">
                                {message.text}
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {messages.length === 0 && (
                        <div class="muuzbox-loader-logo-sm fadeIn">
                          <img src="img/muuzbox-logo.png" alt="Muuzbox logo" />
                          <div class="muuzbox-loader-blob"></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <AccordionList
                  data={groupedTracks}
                  setRequestedTrack={setRequestedTrackSetter}
                  pickTrack={pickTrack}
                  listTitle={"Music Tracks by Genre"}
                  mode={"dark"}
                />
                <h2 className="text-white uppercase mt-2">Individual Tracks</h2>
                <ul className=" divide-y divide-gray-200">
                  {jukebox &&
                    jukebox.map((item, i) => (
                      <li
                        className="flex items-center py-4 px-3 jukebox-divider"
                        key={i}
                        onClick={(event) => {
                          setRequestedTrack(item);
                          pickTrack(event, item, i);
                        }}
                      >
                        <span className="text-gray-100 text-lg font-black font-medium">
                          {i + 1}.
                        </span>
                        <img
                          className=" rounded-full object-cover mr-1"
                          src={item.coverart}
                          style={{ width: "48px" }}
                          alt={item.track}
                        />
                        <div className="flex-1" style={{ width: "50%" }}>
                          <h3 className="text-lg font-medium text-white overflow-hidden">
                            {item.artist}
                          </h3>
                          <p className="text-gray-100 text-base">
                            {item.track}{" "}
                          </p>
                          <p className="text-gray-100 text-base">
                            {item.genre}{" "}
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="muuzbox-shop text-white p-20">
                <ul>
                  <li>
                    <h2>THIS IS OBAH</h2>
                    <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                      <img
                        src="https://thisisobah.com/img/icons/obah-entertainment.png"
                        title="This Is Obah Entertainment"
                        alt="This Is Obah Entertainment"
                      />
                    </div>
                  </li>
                  <li>
                    <h2>Muuzbox Merch</h2>
                    <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FBs7gqBrNUtMBmM7jgEyGmCKtSNl1%2Ffiles%2Fkali-zing-shirt.png?alt=media&token=f3a310a5-ebf8-401f-9d32-917032bc8c44"
                        title="Muuzbox Merch"
                        alt="Muuzbox Merch"
                      />
                    </div>
                  </li>
                  <li>
                    <h2>CTFO MUSIC</h2>
                    <div className="rounded-2xl bg-gray-100 h-full p-4 panel-fade">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/chatbout001.appspot.com/o/users%2FL8s7DzpsAvabSIyNx0xxeuhuryM2%2Ffiles%2FCTFO-Music-Logo.png?alt=media&token=b0900a88-84e0-4471-9c27-b038ab1d1995"
                        title="ctfo music"
                        alt="ctfo music"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
