import styles from './Title.css'
import React from 'react'

const Title = (props) => {
  const titleArtist = props.artist;
  const titleSong = props.title;
  const releasePath = props.releasePath;
  return <a className="text-white text-sm hidden lg:block" href={'/releases/' + releasePath} ><div><strong>{titleSong}</strong><br/>{titleArtist}</div></a>
}

export default Title
