import React, { useEffect, useState, useRef } from "react";
import { auth, db } from "../firebase-config";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Jukebox } from "./Jukebox";
import { AutoPlay } from "./AutoPlay";
import { Users } from "./Users";
import { ChatList } from "./ChatList";
import { HiddenChatList } from "./HiddenChatList";
import { LoadingAnimation } from "./LoadingAnimation";
import { Chat } from "./Chat";
import { SideBarMenu } from "./SideBarMenu";
import { Avatar } from "./Avatar";
import { ActiveConvos } from "./ActiveConvos";
import Cookies from "universal-cookie";
import { useIsVisible } from "../hooks/useIsVisible";
const cookies = new Cookies();
export const AppLayout = ({
  appMode,
  children,
  isAuth,
  setIsAuth,
  setIsInChat,
  setChatId,
  setIsLaunched,
  funcProp,
}) => {
  const ref = useRef();
  const [isInChat2, setIsInChat2] = useState(null);
  const [isInUsers, setIsInUsers] = useState(null);
  const [isInJukebox, setIsInJukebox] = useState(null);
  const [isInAutoPlay, setIsInAutoPlay] = useState(null);
  const [isInHiddenChat, setIsInHiddenChat] = useState(null);
  const [isInChatList, setInChatList] = useState(true);
  const [chatId2, setChatId2] = useState(null);
  const [chatType, setChatType] = useState(null);
  const [trackDetails, setTrackDetails] = useState(null);
  const [isOpen, setIsopen] = useState(false); // sniff if view is desktop or mobile
  const [avatars, setAvatars] = useState({});
  const [userObject, setUserObject] = useState({});
  const [chatUsers, setChatUsers] = useState([]);
  const [showLoadingAni, setShowLoadingAni] = useState(false);
  // const [appMode, setAppMode] = useState(appMode)

  const [mode, setMode] = useState("light");
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const isLaunched = useIsVisible(ref);
  const signUserOut = async () => {
    //cookies.remove("auth-token");
    //cookies.remove("auth-token", {path: "/", domain: "muuzbox.com"})
    removeCookieWithPromise("auth-token", { path: "/" })
      .then(async () => {
        // console.log("Cookie removal complete. Now performing another task.");
        // Perform another task
        await signOut(auth);
        setIsAuth(false);
        setIsInChat(false);
        setIsInChat2(false);
        setIsInUsers(false);
      })
      .catch((error) => {
        console.error("Failed to remove cookie:", error);
      });
  };

  const removeCookieWithPromise = (cookieName, options = {}) => {
    return new Promise((resolve, reject) => {
      try {
        cookies.remove(cookieName, options); // Remove the cookie
        // console.log(`${cookieName} removed successfully`);
        resolve(); // Resolve the promise
      } catch (error) {
        console.error("Error removing the cookie:", error);
        reject(error); // Reject the promise if there's an error
      }
    });
  };

  const closeAllPanels = () => {
    setShowLoadingAni(false);
    setIsInChat(false);
    setIsInChat2(false);
    setIsInUsers(false);
    setIsInJukebox(false);
    setIsInAutoPlay(false);
    setIsInHiddenChat(false);
    setIsopen(false);
  };
  const pickedChat = (chatId, type, trackDetails, users) => {
    closeAllPanels();
    setIsInChat2(true);
    setChatId(chatId);
    setChatId2(null);
    setChatId2(chatId);
    setChatType(type);
    setTrackDetails(trackDetails);
    // console.log('chat users', users);
    setChatUsers(users);
  };
  const loadingAni = () => {
    closeAllPanels();
    // console.log("loadingAni");
    setShowLoadingAni(true);
  };
  const openUsersPanel = () => {
    closeAllPanels();
    setIsInUsers(true);
  };
  const openJukeboxPanel = () => {
    closeAllPanels();
    if(window.location.hostname === "admin.muuzbox.com") {
      setIsInAutoPlay(true)
    } else {
      setIsInJukebox(true);
    }
  };
  const openAutoPlayPanel = () => {
    closeAllPanels();
    // console.log("AutoPlay");
    setIsInAutoPlay(true);
  };
  const openHiddenChatPanel = () => {
    closeAllPanels();
    setShowLoadingAni(false);
    // console.log("showLoadingAni:", showLoadingAni);
    setIsInHiddenChat(true);
  };
  const refreshChatList = () => {
    // setInChatList(false);
    // setTimeout(() => {
    //   setInChatList(true);
    // }, 300);
  };
  const sbm = (message) => {
    if (message === "sign out") signUserOut();
  };

  useEffect(() => {
    // console.log("appMode", appMode);
  }, [appMode]);

  useEffect(() => {
    // console.log('isLaunched', isLaunched)
    // Add listener to update styles
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        onSelectMode(e.matches ? "dark" : "light")
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  React.useEffect(() => {
    if (isLaunched) {
      //callbackFn(); // Trigger a function when the div is visible on view port
      // console.log("isLaunched", isLaunched);
      setIsLaunched(true);
    }
  }, [isLaunched]);

  const onSelectMode = (mode) => {
    setMode(mode);
    if (mode === "dark") {
      // document.body.classList.add('dark-mode')
      document.documentElement.setAttribute("data-theme", "dark");
      // console.log("dark mode");
    } else {
      // document.body.classList.remove('dark-mode')
      document.documentElement.setAttribute("data-theme", "light");
      // console.log("light mode");
    }
  };

  useEffect(() => {
    if (isAuth !== undefined || isAuth !== false) {
      // console.log("isAuth", isAuth);
      setTimeout(async () => {
        // actually go get the user detail from the user collection rather than from the auth
        // when anonymous reloads it loses its displayName and photo.
        const avObj = {};
        try {
          await getUserDetails(auth?.currentUser?.uid).then((userObj) => {
            avObj[auth?.currentUser?.uid] = userObj;
            setUserObject(userObj);
            setAvatars(avObj);
          });
        } catch (err) {
          console.log("error", err);
        }
      }, 3000);
    }
  }, [isAuth]);

  const getUserDetails = async (uid) => {
    // console.log("uid", uid);
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        displayName: docSnap.data().displayName,
        photoURL: docSnap.data().photoURL,
        email: docSnap.data().email,
        nameChanged: docSnap.data().nameChanged || false,
      };
    }
  };

  if (!auth.currentUser) {
    <div>LOADING...</div>;
  }

  const UserName = () => {
    <div className="text-xs text-white">{auth?.currentUser?.displayName}</div>;
  };

  return (
    <div ref={ref} className="App bg-white dark:bg-black">
      <div className="app-container max-w-full">
        {children}
        {isAuth && (
          <>
            <section className="h-screen overflow-hidden flex items-center justify-center max-w-full">
              <div className="flex h-screen antialiased text-gray-800 dark:text-white w-full">
                <div className="flex flex-row h-full w-full overflow-hidden">
                  <div
                    className={`flex flex-col py-8 pr-2 w-7 md:w-40 flex-shrink-0 sidebar ${
                      isOpen === true ? "active" : ""
                    }`}
                    style={{ height: "calc(100dvh - 162px)" }}
                  >
                    <div className="flex flex-col md:flex-row items-center justify-center h-12 w-full my-2">
                      <div className="flex flex-row justify-center">
                        <div
                          onClick={openJukeboxPanel}
                          className="muuzbox-wc-logo"
                        ></div>
                      </div>

                      {/* <div
                        className={`font-bold text-2xl mobile text-gray-800 ${
                          isOpen === true ? "" : "mobile-w-full"
                        }`}
                        onClick={ToggleSidebar}
                      >
                        {isOpen === true ? (
                          <div> &#10094; </div>
                        ) : (
                          <div> &#10095; </div>
                        )}
                      </div> */}
                      {/* <div
                        className="ml-2 font-bold text-2xl notmobile jost-logo-font"
                        onClick={ToggleSidebar}
                      >
                        Muuzbox 1.0
                      </div> */}
                    </div>
                    <UserName />
                    <div className="flex flex-col items-center bg-indigo-100 border border-gray-200 mt-4 w-full py-6 px-6 rounded-lg mobile-chatlist panel-fade relative">
                      <SideBarMenu
                        openUsersPanel={openUsersPanel}
                        openJukeboxPanel={openJukeboxPanel}
                        openAutoPlayPanel={openAutoPlayPanel}
                        openHiddenChatPanel={openHiddenChatPanel}
                        sbm={sbm}
                        funcProp={funcProp}
                        loadingAni={loadingAni}
                        appMode={appMode}
                      />
                      <div className="flex justify-center h-16 w-16 md:h-20 md:w-20 rounded-full border overflow-hidden text-center items-center hidden">
                        {isAuth !== undefined && (
                          <Avatar
                            avObj={avatars}
                            uid={auth?.currentUser?.uid}
                          />
                        )}
                      </div>
                      <div class="text-center items-center text-xs font-black rac-padding text-white">
                        Requests &amp; Chat
                      </div>
                      <div
                        className={`flex flex-col space-y-1 mt-4 -mx-2 ebuebu wc-chatlist  ${
                          isOpen === true ? "hidden" : ""
                        }`}
                      >
                        {isInChatList && (
                          <ChatList
                            pickedChat={pickedChat}
                            thinLayout={true}
                            loadingAni={loadingAni}
                          />
                        )}
                      </div>
                      {/* <div
                        className={`text-sm font-semibold mt-2 menu-open ${
                          isOpen === true ? "" : "menu-fade menu-fade2"
                        }`}
                      >
                        {isAuth !== undefined && (
                          <div>
                            {auth?.currentUser?.displayName
                              ? auth?.currentUser?.displayName
                              : auth?.currentUser?.email}
                          </div>
                        )}
                      </div>
                      <div
                        className={`text-xs text-gray-500 menu-open ${
                          isOpen === true ? "" : "menu-fade menu-fade2"
                        }`}
                      >
                        OBAH Ent. Listener
                      </div>
                      <div
                        className={`flex flex-row items-center mt-3 menu-open ${
                          isOpen === true ? "" : "menu-fade menu-fade2"
                        }`}
                      >
                        <div className="flex flex-col justify-center h-4 w-8 bg-indigo-500 rounded-full">
                          <div className="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
                        </div>
                        <div className="leading-none ml-1 text-xs">Active</div>
                      </div> */}
                    </div>
                    {/* <div
                      className={`flex flex-col mt-8 menu-open ${
                        isOpen === true ? "" : "menu-fade"
                      }`}
                    >
                      <div className="flex flex-row items-center justify-between text-xs">
                        <span className="font-bold">Active Conversations</span>
                        <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">
                          <ActiveConvos uid={auth?.currentUser?.uid} />
                        </span>
                      </div>
                      <div className="flex flex-col space-y-1 mt-1 -mx-2 h-48 overflow-y-auto chatlist wc-chatlist">
                        <ChatList pickedChat={pickedChat} />
                      </div>
                      <div className="flex flex-row items-center justify-between text-xs mt-6">
                        <span className="font-bold">User List</span>
                        <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">
                          7
                        </span>
                      </div>
                      <div className="flex flex-col space-y-1 mt-4 -mx-2">
                        <button className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2">
                          <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                            H
                          </div>
                          <div className="ml-2 text-sm font-semibold">
                            Henry Boyd
                          </div>
                        </button>
                      </div>
                    </div> */}
                  </div>
                  {isInChat2 && (
                    <Chat
                      newchatId={chatId2}
                      chatId={chatId2}
                      chatType={chatType}
                      trackDetails={trackDetails}
                      openJukeboxPanel={openJukeboxPanel}
                      userObj={userObject}
                      chatUsers={chatUsers}
                      refreshChatList={refreshChatList}
                    />
                  )}
                  {isInUsers && <Users refreshChatList={refreshChatList} />}
                  {isInJukebox && <Jukebox userObj={userObject} />}
                  {isInAutoPlay && <AutoPlay userObj={userObject} />}
                  {isInHiddenChat && (
                    <HiddenChatList
                      pickedChat={pickedChat}
                      thinLayout={false}
                      loadingAni={loadingAni}
                    />
                  )}
                  {/* showLoadingAni */}
                  {showLoadingAni && <LoadingAnimation />}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
};
